import _ from "lodash";
import Im from "immutable";
import * as R from "result-async";
import { type Result, ok, error } from "result-async";
import { matchSwitch } from "@babakness/exhaustive-type-checking";
import qs from "query-string";
import { get, post, put, del, ResponseType, HttpError } from "@app/Api/http";
import type {
  OApiDocument,
  Components,
  PathDefinition,
  SecurityScheme,
  ApiKeySecurityScheme,
  HttpSecurityScheme,
  OAuth2SecurityScheme,
  OidcSecurityScheme,
  ContactRole,
  Parameter,
} from "@fair-space/core/types/openapi";
import {
  SecuritySchemeTypeEnum,
  SecuritySchemePositionEnum,
  ParameterPositionEnum,
} from "@fair-space/core/types/openapi";
import {
  ROUTE_ROLE_ATTRIBUTE,
  PARAM_ROLE_ATTRIBUTE,
  PARAM_VALUE_ATTRIBUTE,
  ID_JSONPATH,
  RouteRole,
  ParameterRole,
} from "@fair-space/core/types/openapi-extension";
import type {
  ApiDocument,
  PostApiDocBody,
  PutApiDocBody,
  PostCatalogBody,
  PutCatalogBody,
  HarvestingConstraints,
} from "@fair-space/core/types";
import type {
  SecurityCredentials,
  ApiKeySecurityCredentials,
  PutSecurityCredentialsBody,
} from "@fair-space/core/types/securityCredentials";
import { Catalog, CredentialType } from "@fair-space/core/types";
import { getConfig } from "@app/config";
import type { UserData } from "@app/Models/user";

const config = getConfig();

// Reexports {{{1

export type {
  OApiDocument,
  ApiDocument,
  SecurityScheme,
  ApiKeySecurityScheme,
  HttpSecurityScheme,
  OAuth2SecurityScheme,
  OidcSecurityScheme,
};

export { SecuritySchemeTypeEnum, SecuritySchemePositionEnum };

// Backend {{{1

// APIs {{{2

export const defaultHarvestingConstraints: HarvestingConstraints = {
  schedulingConstraints: {},
  concurrencyConstraints: {
    concurrency: 20,
    timeoutMs: 5000,
    intervalMs: 100,
    intervalCap: 1,
  },
  logicalConstraints: {
    limit: undefined,
  }
};

export enum RetrievalTypeEnum {
  ONE_STEP = "oneStep",
  TWO_STEP = "twoStep",
}

export const API_KEY_SECURITY_KEY = "apiKey";

function mkSecurityScheme(
  type: SecuritySchemeTypeEnum | "",
  position: SecuritySchemePositionEnum,
  apiKeyParam: string
): Components | null {
  function mkApiKeySecurityScheme(): Components {
    return {
      securitySchemes: {
        [API_KEY_SECURITY_KEY]: {
          type: SecuritySchemeTypeEnum.API_KEY,
          name: apiKeyParam,
          in: position,
        },
      },
    };
  }

  return type
    ? matchSwitch(type, {
        [SecuritySchemeTypeEnum.API_KEY]: () => mkApiKeySecurityScheme(),
        [SecuritySchemeTypeEnum.HTTP]: () => null, //TODO
        [SecuritySchemeTypeEnum.OAUTH_2]: () => null, //TODO
        [SecuritySchemeTypeEnum.OIDC]: () => null, //TODO
      })
    : null;
}

export function retrievalType2routeRoles(retrievalType: RetrievalTypeEnum): RouteRole[] {
  return matchSwitch(retrievalType, {
    [RetrievalTypeEnum.TWO_STEP]: () => [RouteRole.GetAllIds, RouteRole.GetOneMetadata],
    [RetrievalTypeEnum.ONE_STEP]: () => [RouteRole.GetOneMetadata],
  });
}

export function routeRoles2retrievalType(routeRoles: RouteRole[]): RetrievalTypeEnum | null {
  return Im.Set(routeRoles).equals(Im.Set([RouteRole.GetAllIds, RouteRole.GetOneMetadata]))
    ? RetrievalTypeEnum.TWO_STEP
    : Im.Set(routeRoles).equals(Im.Set([RouteRole.GetAllMetadata]))
    ? RetrievalTypeEnum.ONE_STEP
    : null;
}

function getPathsArray(oApiDoc: OApiDocument): PathDefinition[] {
  return _.flatten(Object.values(oApiDoc.paths).map(p => Object.values(p)));
}

export function getRouteRoles(oApiDoc: OApiDocument): RouteRole[] {
  return getPathsArray(oApiDoc).map(i => i[ROUTE_ROLE_ATTRIBUTE]);
}

export function getRetrievalType(oApiDoc: OApiDocument): RetrievalTypeEnum | null {
  return routeRoles2retrievalType(getRouteRoles(oApiDoc));
}

function getRoutePair(
  oApiDoc: OApiDocument,
  role: RouteRole
): [string, PathDefinition] | undefined {
  return Object.entries(oApiDoc.paths).find(pair => pair[1].get?.[ROUTE_ROLE_ATTRIBUTE] === role);
}

export function getListPath(oApiDoc: OApiDocument): string | null {
  const routePair = getRoutePair(oApiDoc, RouteRole.GetAllIds);
  if (routePair) {
    const path = routePair[0];
    const params = queryParams2Path(routePair[1].get?.parameters || []);
    return `${path}${params.length > 0 ? `?${params}` : ""}`;
  } else {
    return null;
  }
}

export function getOntPath(oApiDoc: OApiDocument): string | null {
  const routePair = getRoutePair(oApiDoc, RouteRole.GetOneMetadata);
  if (routePair) {
    const path = routePair[0];
    const params = queryParams2Path(routePair[1].get?.parameters || []);
    return `${path.replace(/{.*}/, "{}")}${params.length > 0 ? `?${params}` : ""}`;
  } else {
    return null;
  }
}

export function getOntIdSelector(oApiDoc: OApiDocument): string | null {
  const routePair = getRoutePair(oApiDoc, RouteRole.GetAllIds);
  const selector = routePair?.[1].get?.[ID_JSONPATH];
  return selector ? selector : null;
}

function mkParam({
  pos,
  role,
  name,
  value,
  description,
}: {
  pos: ParameterPositionEnum;
  role?: ParameterRole;
  name: string;
  value?: string;
  description?: string;
}): Parameter {
  const idInParam: boolean = value === "{}";

  const res = {
    description,
    in: pos,
    ...(idInParam
      ? { [PARAM_ROLE_ATTRIBUTE]: ParameterRole.Id }
      : role
      ? { [PARAM_ROLE_ATTRIBUTE]: role }
      : {}),
    name,
    required: true,
    ...(value && !idInParam ? { [PARAM_VALUE_ATTRIBUTE]: value } : {}),
    schema: {
      type: "string",
    },
  };
  return res;
}

type PathAndParams = {
  path: string;
  params: Parameter[];
};

function parsePath(pathWithParams: string): PathAndParams {
  const paramsSplit = pathWithParams.split("?");
  const [cleanPath, paramsStr] = paramsSplit;
  const paramsPairs = paramsStr ? Object.entries(qs.parse(paramsStr, { decode: false })) : null;
  const paramsPairsSimplified = paramsPairs
    ? (paramsPairs.filter(([_name, value]) => _.isString(value)) as [string, string][]) //ignore string[] and null for now
    : null;
  const params: Parameter[] = paramsPairsSimplified
    ? paramsPairsSimplified.map(([name, value]) =>
        mkParam({ pos: ParameterPositionEnum.QUERY, name, value })
      )
    : [];

  return { path: cleanPath, params };
}

function parseOntologyPath(pathWithParams: string): PathAndParams {
  const { path: cleanPath, params: queryParams } = parsePath(pathWithParams);

  return cleanPath.includes("{}")
    ? {
        path: cleanPath.replace("{}", "{id}"),
        params: [
          ...queryParams,
          mkParam({
            pos: ParameterPositionEnum.PATH,
            role: ParameterRole.Id,
            name: "id",
            description: "Ontology identifer",
          }),
        ],
      }
    : {
        path: cleanPath,
        params: queryParams,
      };
}

export function queryParams2Path(params: Parameter[]): string {
  const rec: Record<string, unknown> = params.reduce(
    (res, p) =>
      p.in === ParameterPositionEnum.QUERY
        ? p[PARAM_ROLE_ATTRIBUTE] === ParameterRole.Id
          ? { ...res, [p.name]: "{}" }
          : { ...res, [p.name]: p[PARAM_VALUE_ATTRIBUTE] }
        : res,
    {}
  );
  return qs.stringify(rec, { encode: false });
}

export type ApiDocumentData = {
  apiTitle: string;
  apiDescription: string;
  apiVersion: string;
  apiTermsUrl: string;
  contactEmail: string;
  contactName: string;
  contactUrl: string;
  contactRole: ContactRole | undefined;
  serverUrl: string;
  securityScheme: null | SecuritySchemeTypeEnum;
  securitySchemePosition: SecuritySchemePositionEnum;
  secCredId: null | string;
  apiKeyParam: string;
  apiKeyValue: string;
  retrievalType: RetrievalTypeEnum;
  listPath: string;
  ontIdSelectorJsonPath: null | string;
  ontPath: string;
  harvestMaxConcurrent: string;
  harvestTimeoutMs: string;
  harvestIntervalMs: string;
  harvestIntervalCap: string;
  harvestLimit: string;
  lastChecked: Date | undefined;
}

//export type ApiDocumentData = {
  //apiTitle: string;
  //apiDescription: string;
  //apiVersion: string;
  //apiTermsUrl: string;
  //contactEmail: string;
  //contactName: string;
  //contactUrl: string;
  //contactRole: ContactRole | undefined;
  //serverUrl: string;
  //securityScheme: null | SecuritySchemeTypeEnum;
  //securitySchemePosition: SecuritySchemePositionEnum;
  //secCredId: string|null;
  //apiKeyParam: string;
  //apiKeyValue: string;
  //listPath: string;
  //ontIdSelector: string;
  //ontPath: string;
  //harvestMaxConcurrent: string;
  //harvestTimeoutMs: string;
  //harvestIntervalMs: string;
  //harvestIntervalCap: string;
  //harvestLimit: string;
  //lastChecked: Date | undefined;
//};

export function mkApiDocument(data: ApiDocumentData): PostApiDocBody|PutApiDocBody {
  const securityScheme = mkSecurityScheme(
    data.securityScheme || "",
    data.securitySchemePosition,
    data.apiKeyParam
  );
  const { path: listPath, params: listPathParams } = parsePath(data.listPath);
  const { path: ontPath, params: ontPathParams } = parseOntologyPath(data.ontPath);
  const contactRole = data.contactRole;

  function mkPath({
    path,
    role,
    description,
    parameters,
    ontIdSelectorJsonPath,
  }: {
    path: string;
    role: RouteRole;
    description: string;
    parameters: Parameter[];
    ontIdSelectorJsonPath?: string;
  }): PathDefinition | Record<string, never> {
    return path.length > 0
      ? {
          [path]: {
            get: {
              [ROUTE_ROLE_ATTRIBUTE]: role,
              ...(securityScheme ? { security: [{ [API_KEY_SECURITY_KEY]: [] }] } : {}),
              responses: {
                "200": {
                  content: {
                    "application/json": {
                      schema: {
                        type: "object",
                      },
                    },
                  },
                  description,
                },
              },
              parameters,
              ...(ontIdSelectorJsonPath ? { [ID_JSONPATH]: ontIdSelectorJsonPath } : {}),
              summary: "get the list of ontologies",
            },
          },
        }
      : {};
  }

  return {
    doc: {
      openapi: "3.0.0",
      info: {
        title: data.apiTitle,
        description: data.apiDescription,
        version: data.apiVersion,
        termsOfService: data.apiTermsUrl,
        contact: {
          email: data.contactEmail,
          name: data.contactName,
          url: data.contactUrl,
          "x-role": contactRole,
        },
      },
      servers: [
        {
          description: "Production server",
          url: data.serverUrl,
        },
      ],
      ...(securityScheme ? { components: securityScheme } : {}),
      tags: [],
      paths: {
        ...mkPath({
          path: listPath,
          role: RouteRole.GetAllIds,
          description: "get list of ontologies",
          parameters: listPathParams,
          ontIdSelectorJsonPath: data.ontIdSelectorJsonPath ? data.ontIdSelectorJsonPath : undefined,
        }),
        ...mkPath({
          path: ontPath,
          role: RouteRole.GetOneMetadata,
          description: "get ontology metadata",
          parameters: ontPathParams,
        }),
      },
    },
    harvestingConstraints: {
      schedulingConstraints: {},
      concurrencyConstraints: {
        concurrency: data.harvestMaxConcurrent ? parseInt(data.harvestMaxConcurrent) : undefined,
        timeoutMs: data.harvestTimeoutMs ? parseInt(data.harvestTimeoutMs) : undefined,
        intervalMs: data.harvestIntervalMs ? parseInt(data.harvestIntervalMs) : undefined,
        intervalCap: data.harvestIntervalCap ? parseInt(data.harvestIntervalCap) : undefined,
      },
      logicalConstraints: {
        limit: data.harvestLimit ? parseInt(data.harvestLimit) : undefined,
      }
    },
    lastChecked: data.lastChecked,
  };
}

export function detectSecurity(oApi: OApiDocument): Result<ApiKeySecurityScheme|null, string> {
  const schemes: SecurityScheme[] = Object.values(oApi.components?.securitySchemes || {});
  const scheme = schemes[0];

  return (
    schemes.length === 0 ? // No security scheme detected
      ok(null)
    : schemes.length > 1 ?
      error("Only a single security scheme is now supported")
    : scheme.type !== SecuritySchemeTypeEnum.API_KEY ?
      error("Only API_KEY security scheme currently supported")
    : ok(scheme as ApiKeySecurityScheme)
  );
}

function mkApisUrl(): string {
  return `${config.SERVER_URL}/apis`;
}

function mkApiUrl(apiId: string): string {
  return `${mkApisUrl()}/${apiId}`;
}

export function getAPIDocuments(): R.ResultP<ApiDocument[], HttpError> {
  return get(mkApisUrl());
}

export function getAPIDocument(apiId: string, userData: UserData): R.ResultP<ApiDocument, HttpError> {
  return get(mkApiUrl(apiId), userData);
}

export function postAPIDocument(userData: UserData, data: ApiDocumentData): R.ResultP<string, HttpError> {
  return post(mkApisUrl(), userData, ResponseType.JSON, mkApiDocument(data));
}

export function putAPIDocument(apiId: string, userData: UserData, data: ApiDocumentData): R.ResultP<void, HttpError> {
  return put(mkApiUrl(apiId), userData, mkApiDocument(data));
}

export function patchAPIDocument(userData: UserData, apiDoc: ApiDocument): R.ResultP<void, HttpError> {
  // Currently, as the API does not support PATCH, it is realised as PUT
  const cleaned = _.omit(apiDoc, ["id", "date", "errors", "userId"]);
  return put(mkApiUrl(apiDoc.id), userData, cleaned);
}

export function deleteAPIDocument(apiId: string, userData: UserData): R.ResultP<void, HttpError> {
  return del(mkApiUrl(apiId), userData);
}

// Credentials API {{{2

function mkSecCredsUrl(apiId: string): string {
  return `${mkApiUrl(apiId)}/security-credentials`;
}

function mkSecCredUrl(apiId: string, secCredId: string): string {
  return `${mkSecCredsUrl(apiId)}/${secCredId}`;
}

export async function getSecCred(userData: UserData, apiId: string): R.ResultP<{secCredId: string; value: string}|null, HttpError> {
  const respR: R.Result<SecurityCredentials[], HttpError> = await get(mkSecCredsUrl(apiId), userData);
  if (R.isError(respR)) {
    return R.error(respR.error);
  }
  const resp = respR.ok;
    if (resp.length > 1) {
    return R.error("There are multiple security credentials, this should not happen");
  }
  if (resp.length === 0) {
    return R.ok(null);
  }
  const secrets = resp[0].secrets;
  if (!secrets) {
    return R.error("Response does not contain secrets");
  }
  const creds = secrets[API_KEY_SECURITY_KEY];
  if (!creds) {
    return R.error(`${API_KEY_SECURITY_KEY} is not present in secrets`);
  }
  const apiKey = (creds as ApiKeySecurityCredentials).apiKey;
  if (!apiKey) {
    return R.error(`apiKey is not present in secrets[${API_KEY_SECURITY_KEY}]`);
  }
  return R.ok({
    secCredId: resp[0].id,
    value: apiKey,
  });
}

export async function upsertSecCred(apiId: string, userData: UserData, apiKeyValue: string): R.ResultP<void, HttpError> {
  const secrets: PutSecurityCredentialsBody = {
    secrets: {
      [API_KEY_SECURITY_KEY]: {
        type: CredentialType.API_KEY,
        apiKey: apiKeyValue,
      },
    },
  };
  const existingR: R.Result<SecurityCredentials[], HttpError> = await get(mkSecCredsUrl(apiId), userData);
  if (R.isError(existingR)) {
    return R.error(existingR.error);
  }
  const existing = existingR.ok;
  if (existing.length > 0) {
    await put(mkSecCredUrl(apiId, existing[0].id), userData, secrets);
    return R.ok(void(0));
  } else {
    await post(mkSecCredsUrl(apiId), userData, ResponseType.NONE, secrets);
    return R.ok(void(0));
  }
}

export async function postAPIDocAndCred(userData: UserData, data: ApiDocumentData): R.ResultP<string, HttpError> {
  const apiIdR = await postAPIDocument(userData, data);
  if (R.isError(apiIdR)) {
    return R.error(apiIdR.error);
  }
  const apiId = apiIdR.ok;
  if (apiId) {
    if (data.securityScheme) {
      await upsertSecCred(apiId, userData, data.apiKeyValue);
    }
    return R.ok(apiId);
  } else {
    return R.error("Document POST did not return apiId");
  }
}

export async function putAPIDocAndCred(apiId: string, userData: UserData, data: ApiDocumentData): R.ResultP<void, Response|string> {
  //return P.pipeA
    //(await putAPIDocument(apiId, userData, data))
    //.thru(R.okChain<void, void, HttpError>(async () => await upsertSecCred(apiId, userData, data.apiKeyValue)))
    //.value();
  const resultP1 = await putAPIDocument(apiId, userData, data);
  if (R.isError(resultP1)) {
    return R.error(resultP1.error);
  } else if (data.apiKeyValue) {
    const resultP2 = await upsertSecCred(apiId, userData, data.apiKeyValue);
    if (R.isError(resultP2)) {
      return R.error(resultP2.error);
    } else {
      return R.ok(void(0));
    }
  } else {
    return R.ok(void(0));
  }
}

// Catalog API {{{1

function mkCatalogsUrl(apiId: string): string {
  return `${config.SERVER_URL}/apis/${apiId}/catalog`;
}

function mkCatalogUrl(apiId: string, catalogId: string): string {
  return `${config.SERVER_URL}/apis/${apiId}/catalog/${catalogId}`;
}

export function getCatalog(apiId: string, userData: UserData): R.ResultP<Catalog|null, HttpError> {
  return get(mkCatalogsUrl(apiId), userData);
}

export function postCatalog(apiId: string, userData: UserData, data: PostCatalogBody): R.ResultP<string, HttpError> {
  return post(mkCatalogsUrl(apiId), userData, ResponseType.JSON, data);
}

export function putCatalog(apiId: string, catalogId: string, userData: UserData, data: PutCatalogBody): R.ResultP<void, HttpError> {
  return put(mkCatalogUrl(apiId, catalogId), userData, data);
}
