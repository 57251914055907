/* eslint-disable react/display-name */
import _ from "lodash";
import * as R from "result-async";
import * as P from "pipeout";
import { matchSwitch } from "@babakness/exhaustive-type-checking";
import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import MuiAlert from "@mui/material/Alert";
import {
  AppBar,
  Button,
  CircularProgress,
  CssBaseline,
  Modal,
  Toolbar,
  Typography,
  Box,
  Stack,
  Drawer,
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Backdrop,
} from "@mui/material";
import {
  Storage as RepositoryIcon,
} from "@mui/icons-material";
import { grey as GREY } from "@mui/material/colors";
import logo from "./eSDF-logo.png";
import type { TryCancelProp, IndicationProps, PreviewProp, CallAsyncProp } from "@app/Components/definitions";
import { Repositories } from "@app/Components/Repositories";
import { Login } from "@app/Components/Login";
import { AccountBadge } from "./Components/AccountBadge";
import { ModalView } from "./Components/Modal";
import { ConfirmationDialog } from "./Components/UIComponents";
import * as auth from "@app/Auth";
import {HttpError} from "./Api/http";

enum ScreensEnum {
  REPOSITORIES = "Repositories",
  LOGIN = "Login",
}

const theme = createTheme({
  typography: {
    h1: {
      fontSize: "1.8rem",
      marginBottom: "0.5rem",
      color: GREY[800],
    },
    h2: {
      fontSize: "1.2rem",
      marginBottom: "0.7rem",
      fontWeight: "bold",
      color: GREY[800],
    },
    h4: {
      fontSize: "1.7rem",
      color: GREY[800],
    },
  },
});

const drawerWidth = 250;

function App(): JSX.Element {
  const [userData, setUserData] = React.useState(auth.retrieveStoredLogin());
  const [screen, setScreen] = React.useState(ScreensEnum.LOGIN);
  const [loading, setLoading] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(null as string | null);
  const [modalContents, setModalContents] = React.useState(null as JSX.Element | null);
  const [modalCloseFn, setModalCloseFn] = React.useState(undefined as undefined | (() => () => void));
  const [cancelAction, setCancelAction] = React.useState(null as null | (() => () => void));
  const [confirmationAnswer, setConfirmationAnswer] = React.useState(null as null|boolean);

  //React.useEffect(() => console.log({modalContents, jsonPreview}), [modalContents, jsonPreview]);

  // Manage confirmation
  React.useEffect(
    () => {
      if (cancelAction && confirmationAnswer !== null) {
        if (confirmationAnswer === true) {
          cancelAction();
        }
        setCancelAction(null);
        setConfirmationAnswer(null);
      }
    },
    [cancelAction, confirmationAnswer]
  );

  function logout(): void {
    auth.logout();
    setUserData(null);
  }

  //// Routines for components ////

  function handleComponentError(msgOrResp: string|Response): void {
    if (_.isString(msgOrResp)) {
      setErrorMsg(msgOrResp);
    } else {
      if (msgOrResp.status === 401) { // Unauthorized
        logout();
      } else {
        console.error("A component reports problem:");
        console.error(msgOrResp);
        if (!msgOrResp.status) { // Not a network error, but uncaught exception
          setErrorMsg(msgOrResp.toString());
        } else if (msgOrResp.body) {
          msgOrResp.text().then(
            body => setErrorMsg(`${msgOrResp.statusText} (${msgOrResp.status}): ${body}`)
          );
        } else {
          setErrorMsg(`${msgOrResp.statusText} (${msgOrResp.status})`);
        }
      }
    }
  }

  async function callAsync<T>(
    apiCallFn: () => R.ResultP<T, HttpError>,
    processingFn: (result: T) => void,
    options?: { onError?: (error: HttpError) => void, progress?: boolean }
  ): Promise<void> {
    if (options?.progress === undefined || options?.progress === true) { setLoading(true); }
    const resultR = await apiCallFn();
    setLoading(false);
    P.pipe
      (resultR)
      .thru(R.okDo<T, HttpError>(processingFn))
      .thru(R.errorDo<T, HttpError>(error => { setLoading(false); options?.onError ? options?.onError(error) : handleComponentError(error); }))
      .value();
  }

  function preparePreview(contents: JSX.Element|null, onClose?: () => void): void {
    setModalContents(contents);
    setModalCloseFn(() => onClose);
  }

  //// Rendering ////

  function tellVariantForScreenButton(
    s: ScreensEnum
  ): "contained" | "outlined" {
    return s === screen ? "contained" : "outlined";
  }

  function renderLoginButtonOrUserBadge(): JSX.Element | JSX.Element[] {
    return (
      userData ?
        <AccountBadge
          userData={userData}
          onAccount={() => setScreen(ScreensEnum.LOGIN)}
          onLogout={logout}
          />
        :
        <Button
          color="primary"
          variant={tellVariantForScreenButton(ScreensEnum.LOGIN)}
          onClick={() => setScreen(ScreensEnum.LOGIN)}
        >
          Login
        </Button>
    );
  }

  function renderAppBar(): JSX.Element {
    return (
      <AppBar
        position="fixed"
        sx={{
          zIndex: theme => theme.zIndex.drawer + 1,
          background: GREY[200],
          color: GREY[900],
          mb: 2,
        }}
      >
        <Toolbar>
          <Typography variant="h6" sx={{ mr: 4 }}>
            FAIRCat
          </Typography>
          <Box sx={{ flexGrow: 1 }}>{/*<img src={logo} alt="Logo" />*/}</Box>
          <Box>{renderLoginButtonOrUserBadge()}</Box>
        </Toolbar>
      </AppBar>
    );
  }

  function renderDrawer(): JSX.Element {
    return (
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          ["& .MuiDrawer-paper"]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: "auto" }}>
          <List>
            <ListItemButton
              selected={screen === ScreensEnum.REPOSITORIES}
              disabled={!userData}
              onClick={() => setScreen(ScreensEnum.REPOSITORIES)}
            >
              <ListItemIcon>
                <RepositoryIcon />
              </ListItemIcon>
              <ListItemText primary={ScreensEnum.REPOSITORIES} />
            </ListItemButton>
          </List>
        </Box>
      </Drawer>
    );
  }

  const componentProps: IndicationProps & CallAsyncProp & PreviewProp & TryCancelProp = {
    onError: handleComponentError,
    onLoad: setLoading,
    callAsync,
    onPreview: preparePreview,
    tryCancel: (canCancel, cancelAction1) => {
      if (canCancel) {
        cancelAction1();
      } else {
        setCancelAction(() => cancelAction1); // cancelAction will indicate the dialog is necessary
      }
    }
  };

  function renderScreen(): JSX.Element {
    return userData ? (
      matchSwitch(screen, {
        [ScreensEnum.REPOSITORIES]: () => (
          <Repositories {...componentProps} userData={userData} />
        ),
        [ScreensEnum.LOGIN]: () => (
          <Login {...componentProps} userData={userData} onLogin={setUserData} />
        ),
      })
    ) : (
        <Login {...componentProps} userData={userData} onLogin={setUserData} />
      );
  }

  function renderLoading(): JSX.Element {
    return (
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  function renderError(): JSX.Element {
    return (
      <Modal open={errorMsg !== null} onClose={() => setErrorMsg(null)}>
        <Box
          sx={{
            position: "absolute",
            width: 400,
            top: "calc(100vh / 2)",
            left: "calc(100vw / 2 - 200px)",
          }}
        >
          <MuiAlert elevation={6} variant="filled" severity="error">
            {errorMsg}
          </MuiAlert>
        </Box>
      </Modal>
    );
  }

  function renderModal(): JSX.Element {
    return (
      <ModalView
        contents={modalContents}
        onClosed={() => {
          setModalContents(null);
          if (modalCloseFn) { modalCloseFn(); }
        }}
        />
    );
  }

  function renderCancelConfirmationDialog(): JSX.Element {
    return (
      <ConfirmationDialog
        question="There are unsaved changes, really cancel?"
        open={cancelAction !== null}
        onYes={() => setConfirmationAnswer(true)}
        onNo={() => setConfirmationAnswer(false)}
      />
    );
  }

  function renderFooter(): JSX.Element {
    return (
      <Box
        sx={{
          position: "fixed",
          zIndex: theme => theme.zIndex.drawer + 1,
          width: "100vw",
          height: 100,
          mt: 2,
          backgroundColor: GREY[200],
          color: GREY[800],
        }}
      >
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ pt: "5px", pl: 1, pr: 1, fontSize: "85%" }}
        >
          <Box component="span" sx={{pr: 1}}>Designed and developed by</Box>
          <img src={logo} height={40}/>
          <Box component="span" sx={{pl: 2}}>Funded by FAIRsFAIR. FAIRsFAIR “Fostering FAIR Data Practices In Europe” has received funding from the European Union’s Horizon 2020 project call H2020-INFRAEOSC-2018-2020 Grant agreement 831558.</Box>
        </Stack>
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ display: "flex" }}>
        {renderAppBar()}
        {renderDrawer()}
        <Box
          component="main"
          sx={{ flexGrow: 1, height: "calc(100vh - 68px)", px: 2 }}
        >
          <Toolbar />
          {renderScreen()}
          <Toolbar />
        </Box>
      </Box>
      {renderFooter()}
      {renderLoading()}
      {renderError()}
      {renderModal()}
      {renderCancelConfirmationDialog()}
    </ThemeProvider>
  );
}

// This is just a wrapper function with all the pesky "global wrappers" such as recoil and
// react-router. This way they don"t polute the main App component.
export default function wrappedApp(): JSX.Element {
  return <App />;
}
