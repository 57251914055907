import React from "react";
import {matchSwitch} from "@babakness/exhaustive-type-checking";
import {
  Box,
  Button,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
 } from "@mui/material";
import {
  Pause as PauseIcon,
  Stop as CancelIcon,
  PlayArrow as ResumeIcon,
  RestartAlt as RestartIcon,
} from "@mui/icons-material";
import {
  yellow as YELLOW,
  green as GREEN,
  red as RED,
  grey as GREY,
} from "@mui/material/colors";
import type { JobInfoRecord} from "@app/Models/harvest";
import {
  JobStatus,
} from "@app/Api/harvests";
import { JobStatusChip } from "./JobStatusChip";
import { jobPercent, jobEta } from "@app/Models/harvest";

type Props = {
  title: string;
  jobInfoRecord: JobInfoRecord;
  restartAction: () => void;
  extraButtons?: JSX.Element;
}

export function JobInfoPane(props: Props): JSX.Element {
  const jobInfo = props.jobInfoRecord.jobInfo;

  function status2color(intensity: string): string {
    type Color = Record<string, string>
    return (
      jobInfo?.status ?
        matchSwitch(jobInfo.status, {
          [JobStatus.RUNNING]: () => (YELLOW as Color)[intensity],
          [JobStatus.SCHEDULED]: () => (YELLOW as Color)[intensity],
          [JobStatus.PAUSED]: () => (GREY as Color)[intensity],
          [JobStatus.FINISHED]: () => (GREEN as Color)[intensity],
          [JobStatus.FAILED]: () => (RED as Color)[intensity],
          [JobStatus.UNKNOWN]: () => (GREY as Color)[intensity],
        })
      : ""
    );
  }

  function renderError(error: string, index: number): JSX.Element {
    return (
      <Box key={index}>
        <pre>
          {error}
        </pre>
      </Box>
    );
  }

  function renderJobInfo(): JSX.Element {
    return (
      jobInfo ?
        <Table sx={{backgroundColor: status2color("100")}}>
          <TableBody>
            <TableRow>
              <TableCell>Status:</TableCell>
              <TableCell>
              <JobStatusChip jobInfoRecord={props.jobInfoRecord} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Started:</TableCell>
              <TableCell>
                {jobInfo.startedDate ? (new Date(jobInfo.startedDate)).toLocaleString() : "Not started, yet"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Finished:</TableCell>
              <TableCell>
                {jobInfo.finishedDate ? (new Date(jobInfo.finishedDate)).toLocaleString() : jobEta(jobInfo)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Items processed/Total items:</TableCell>
              <TableCell>
                {jobInfo.processedItems}/{jobInfo.totalItems}
                <LinearProgress
                  variant="determinate"
                  value={jobPercent(jobInfo)}
                />
              </TableCell>
            </TableRow>
            {jobInfo.warnings && jobInfo.warnings.length > 0 ?
              <TableRow>
                <TableCell sx={{color: "warning.main"}}>Warnings</TableCell>
                <TableCell sx={{color: "warning.main"}}>
                  {JSON.stringify(jobInfo.warnings, null, 2)}
                </TableCell>
              </TableRow>
            : <></>
            }
            {jobInfo.errors && jobInfo.errors.length > 0 ?
              <TableRow>
                <TableCell sx={{color: "error.main"}}>Errors</TableCell>
                <TableCell sx={{color: "error.main"}}>
                  {jobInfo.errors.map(renderError)}
                </TableCell>
              </TableRow>
            : <></>
            }
          </TableBody>
        </Table>
      :
        <Stack direction="row" justifyContent="center">
          Metadata generation has not started
        </Stack>
    );
  }

  function renderJobActions() {
    return (
      <Stack direction="row" justifyContent="center" spacing={2}>
        <Button
          variant="contained"
          startIcon={<PauseIcon />}
          disabled={jobInfo?.status !== JobStatus.RUNNING}
        >
          Pause
        </Button>
        <Button
          variant="contained"
          startIcon={<ResumeIcon />}
          disabled={jobInfo?.status !== JobStatus.PAUSED}
        >
          Resume
        </Button>
        <Button
          variant="contained"
          color="warning"
          startIcon={<CancelIcon />}
          disabled={jobInfo?.status === JobStatus.FINISHED || jobInfo?.status === JobStatus.FAILED}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="warning"
          startIcon={<RestartIcon />}
          onClick={props.restartAction}
        >
          Restart
        </Button>
        {props.extraButtons ?
          props.extraButtons
        : <></>
        }
      </Stack>
    );
  }

  return (
    <Stack direction="column" spacing={2} alignItems="center">
      <Typography variant="h2">{props.title}</Typography>
      {renderJobInfo()}
      {renderJobActions()}
    </Stack>
  );
}