// NPM modules.
import React from "react";
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import type {
  SpqlResult,
  SemanticPropertyInfo,
} from "@app/Api/rdf";

// ---

interface Props {
  property: SemanticPropertyInfo;
  properties: SpqlResult;
}

export function PropertiesTable(props: Props): React.FunctionComponentElement<Props> {
  const predIndex = props.properties.names.findIndex(n => n === "pred");
  const objectIndex = props.properties.names.findIndex(n => n === "object");

  function renderPropertyInfo(): JSX.Element {
    const sx = { fontSize: "105%" };
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell sx={sx}>Property URI</TableCell>
              <TableCell sx={sx}>{props.property.uri}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={sx}>Property Range (Subject)</TableCell>
              <TableCell sx={sx}>{props.property.range}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  function renderTableHead(): React.ReactElement {
    return (
      <TableHead>
        <TableRow>
          <TableCell>Predicate</TableCell>
          <TableCell>Object</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  function renderTableRow(row: Array<string>, i: number) {
    const pred = row[predIndex];
    const object = row[objectIndex];

    return (
      <TableRow key={i}>
        <TableCell>{pred}</TableCell>
        <TableCell>{object}</TableCell>
      </TableRow>
    );
  }

  function renderTableBody(): React.ReactElement {
    return (
      <TableBody>
        {props.properties.values.map(renderTableRow)}
      </TableBody>
    );
  }

  return (
    <Stack direction="column" alignItems="start" spacing={2}>
      {renderPropertyInfo()}
      <TableContainer component={Paper}>
        <Table>
          {renderTableHead()}
          {renderTableBody()}
        </Table>
      </TableContainer>
    </Stack>
  );

}
