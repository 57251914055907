import * as R from "result-async";
import jwt_decode from "jwt-decode";
import { getConfig } from "@app/config";
import type { UserData, User } from "@app/Models/user";
import * as storage from "./storage";

const config = getConfig();

export function retrieveStoredLogin(): UserData|null {
  const storedAuth = storage.retrieve();
  return (
    storedAuth ?
      storedAuth.userData
    : null
  );
}

export function login({ onClose }: { onClose?: () => void }): R.ResultP<UserData, string> {
  return new Promise(resolve => {
    let popup: Window | null = null;

    function cancel(): void {
      if (onClose) {
        onClose();
      }
    }

    function receiveMessage(event: MessageEvent): void {
      if (event.source === popup && event.origin === config.SERVER_URL) {
        window.removeEventListener("message", receiveMessage);
        popup?.close();
        if (!event.data) {
          resolve(R.error("Login failed: received empty data"));
        } else {
          const jwtData = jwt_decode<{ user: User }>(event.data);
          if (!jwtData?.user) {
            resolve(R.error("Login failed: received corrupted data"));
          } else {
            const userData: UserData = {
              user: jwtData.user,
              jwt: event.data,
            };
            storage.store({ userData });
            resolve(R.ok(userData));
          }
        }
      }
    }

    popup = window.open(`${config.SERVER_URL}/${config.LOGIN_PATH}`, "LOGIN", "width=800");
    popup?.addEventListener("unload", cancel);
    window.addEventListener("message", receiveMessage, false);
    setTimeout(
      () => window.removeEventListener("message", receiveMessage, false),
      1000 * 60 * 10, // 10 minutes.
    );
  });
}

export function logout(): void {
  storage.wipe();
}
