import * as R from "result-async";
import * as P from "pipeout";
import { UserData } from "@app/Models/user";
import {matchSwitch} from "@babakness/exhaustive-type-checking";

function mkAuthHeader(userData: UserData): HeadersInit {
  return { Authorization: "Bearer " + userData.jwt };
}

const contentJsonHeader: HeadersInit = { "Content-Type": "application/json" };

const acceptJsonHeader: HeadersInit = { Accept: "application/json" };

type ApiRequest<T> = {
  method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
  url: string;
  userData?: UserData;
  body?: T;
};

export enum ResponseType {
  NONE = "none",
  JSON = "json",
  TEXT = "text",
}

async function doRequest<T>({ method, url, userData, body }: ApiRequest<T>): R.ResultP<Response, Response> {
   const responseP = R.promiseToResult(fetch(url, {
    method,
    headers: {
      ...(userData ? { ...mkAuthHeader(userData) } : {}),
      ...(body ? { ...contentJsonHeader } : {}),
      ...acceptJsonHeader,
     },
    ...(body ? { body: JSON.stringify(body) } : {}),
  }));
  return R.either(
    await responseP,
    resp => resp.ok ? R.ok(resp) : R.error(resp),
    err => R.error(err)
  );
    //(
  //if (!response.ok) {
    //throw response;
  //} else {
    //return response;
  //}
}

async function processResponse<T>(responseType: ResponseType, response: Response): R.ResultP<T, string> {
  return (
    !response.body && (responseType === ResponseType.JSON || responseType === ResponseType.TEXT) ?
      R.error("Empty response from " + response.url)
    : matchSwitch(responseType, {
        [ResponseType.NONE]: () => R.promiseToResult(Promise.resolve(void(0))),
        [ResponseType.JSON]: () => R.promiseToResult(response.json()),
        [ResponseType.TEXT]: () => R.promiseToResult(response.text()),
      })
  );
}

export type HttpError = Response|string;

export async function get<T>(url: string, userData?: UserData, responseType = ResponseType.JSON): R.ResultP<T, HttpError> {
  const reqRes = await doRequest({ method: "GET", url, userData });
  return R.isOk(reqRes) ?
    processResponse(responseType, reqRes.ok)
  : reqRes;
}

export async function post<T1, T2>(url: string, userData: UserData, responseType = ResponseType.NONE, body?: T2): R.ResultP<T1, HttpError> {
  const reqRes = await doRequest({ method: "POST", url, userData, body });
  return R.isOk(reqRes) ?
    processResponse(responseType, reqRes.ok)
  : reqRes;
}

export async function put<T>(url: string, userData: UserData, body: T): R.ResultP<void, Response> {
  const resp = await doRequest({ method: "PUT", url, userData, body });
  return P.pipe
    (resp)
    .thru(R.okReplace(void(0)))
    .value();
}

export async function patch<T1, T2>(url: string, userData: UserData, responseType = ResponseType.NONE, body?: T2): R.ResultP<T1, Response|string> {
  const reqRes = await doRequest({ method: "PATCH", url, userData, body });
  return R.isOk(reqRes) ?
    processResponse(responseType, reqRes.ok)
  : reqRes;
}

export async function del(url: string, userData: UserData): R.ResultP<void, Response> {
  const resp = await doRequest({ method: "DELETE", url, userData });
  return (R.okReplace(void(0)))(resp);
}
