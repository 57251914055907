import { matchSwitch } from "@babakness/exhaustive-type-checking";
import React from "react";
import {
  Badge,
  Chip,
  Stack,
} from "@mui/material";
import type { JobInfoRecord} from "@app/Models/harvest";
import { JobStatus } from "@app/Api/harvests";
import { jobPercent } from "@app/Models/harvest";

export type Props = {
  jobInfoRecord: JobInfoRecord;
  onClick?: () => void;
}

export function JobStatusChip(props: Props): JSX.Element {
  const jobInfo = props.jobInfoRecord.jobInfo;
  const loading = props.jobInfoRecord.loading;
  const color: "primary" | "default" | "success" | "error" =
    jobInfo ?
      matchSwitch(jobInfo.status, {
        [JobStatus.RUNNING]: () => "primary",
        [JobStatus.PAUSED]: () => "default",
        [JobStatus.SCHEDULED]: () => "default",
        [JobStatus.FINISHED]: () => "success",
        [JobStatus.FAILED]: () => "error",
        [JobStatus.UNKNOWN]: () => "default",
      })
    : "default";

  return (
    <Stack direction="row">
      <Chip
        color={color}
        variant="filled"
        label={
          loading ?
            "Loading..."
          : jobInfo ?
            jobInfo.status === JobStatus.RUNNING ?
              `${jobPercent(jobInfo)}%`
            : jobInfo.status
          : "None"
        }
        disabled={!jobInfo}
        onClick={props.onClick}
      />
      {jobInfo?.warnings && jobInfo.warnings.length > 0 ?
        <Badge
          color="warning"
          badgeContent={jobInfo.warnings.length}
        />
        : <></>
      }
      {jobInfo?.errors && jobInfo.errors.length > 0 ?
        <Badge
          color="error"
          badgeContent={jobInfo.errors.length}
        />
        : <></>
      }
    </Stack>
  );
}
