import * as R from "result-async";
import React from "react";
import {
  Button,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import type {
  UserData,
} from "@app/Models/user";
import type {
  CallAsyncProp,
} from "@app/Components/definitions";
import {
  FSContainer,
  FSFormGroup,
} from "@app/Components/UIComponents";
import * as auth from "@app/Auth";

type Props = CallAsyncProp &
  {
    userData: UserData|null;
    onLogin: (user: UserData) => void;
  }

export function Login(props: Props): JSX.Element {

  function doLogin(): void {
    props.callAsync(
      () => auth.login({}),
      userData => {
        props.onLogin(userData);
      }
    );
  }

  function renderUserProfile(): JSX.Element {
    return (
      <FSFormGroup heading="User profile">
        <Grid item sm={6}>
          <TextField
            fullWidth
            label="First name"
            variant="standard"
            value={props.userData?.user.firstName || ""}
            disabled
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            fullWidth
            label="Last name"
            variant="standard"
            value={props.userData?.user.lastName || ""}
            disabled
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            fullWidth
            label="E-mail"
            variant="standard"
            value={props.userData?.user.email || ""}
            disabled
          />
        </Grid>
      </FSFormGroup>
    );
  }

  // Rendering.
  return (
    <FSContainer>
      <Stack direction="column" spacing={2} sx={{height: "100%"}}>
        {!props.userData ?
          <Stack direction="row" justifyContent="center" sx={{mt: 2}}>
            <Button
              variant="contained"
              size="large"
              onClick={doLogin}
            >
              Log in with e-SDF keycloak
            </Button>
          </Stack>
          :
            renderUserProfile()
        }
      </Stack>
    </FSContainer>
  );
}
