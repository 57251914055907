import type { StoredAuth } from "./definitions";

const storageKey = "auth";

export function store(sAuth: StoredAuth): void {
  window.localStorage.setItem(storageKey, JSON.stringify(sAuth));
}

export function retrieve(): StoredAuth | null {
  const sAuthStr = window.localStorage.getItem(storageKey);
  if (!sAuthStr) {
    return null;
  } else {
    try {
      const sAuth: StoredAuth = JSON.parse(sAuthStr);
      return sAuth;
    } catch (err) {
      return null;
    }
  }
}

export function wipe(): void {
  window.localStorage.removeItem(storageKey);
}