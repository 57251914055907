/* eslint-disable react/display-name */
import _ from "lodash";
import * as R from "result-async";
import * as P from "pipeout";
import { matchSwitch } from "@babakness/exhaustive-type-checking";
import React from "react";
import { saveAs } from "file-saver";
import type { SxProps } from "@mui/system";
import {
  Stack,
  Box,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Button,
} from "@mui/material";
import {
  Help as InfoIcon,
  Done as FinishedIcon,
  FileDownload as ExportIcon,
  Cancel as CloseIcon,
} from "@mui/icons-material";
import {
  green as GREEN,
  orange as ORANGE,
  red as RED,
} from "@mui/material/colors";
import type {
  Field,
} from "@fair-space/core/analyser";
import {
  getJsonPaths,
} from "@fair-space/core/analyser";
import type {
  SemanticPropertyInfo,
  PostMappingBody,
} from "@fair-space/core/types/mapping";
import type {
  SemanticProperty,
  Mapping,
  MappingLine,
  MappingLineFull,
} from "@app/Models/mapping";
import {
  Domain,
  Endorsement,
  getMetadataItem,
  selectMappingsByDomain,
} from "@app/Models/mapping";
import * as rdfApi from "@app/Api/rdf";
import type { Harvest } from "@app/Api/harvests";
import * as harvestsApi from "@app/Api/harvests";
import * as mappingsApi from "@app/Api/mappings";
import { PropertiesTable } from "./PropertiesTable";
import { FieldSelector } from "@app/Components/FieldSelector";
import { SchemaView } from "@app/Components/SchemaView";
import {
  ApiDocumentProp,
  IndicationProps,
  PreviewProp,
  CancelClientProps,
  UserProp,
  CallAsyncProp,
} from "@app/Components/definitions";
import { Importer, jsonMime } from "@app/Components/Importer";
import { type HttpError } from "@app/Api/http";

interface MetadataInfoRequest {
  metadataFieldJsonPath: string;
  schemaItemUri: string;
}

const noMIRequest: MetadataInfoRequest = {
  metadataFieldJsonPath: "",
  schemaItemUri: "",
};

export type LineRenderer = (mappingLine: MappingLineFull, css: SxProps) => JSX.Element;

type Props =
  IndicationProps &
  CallAsyncProp &
  PreviewProp &
  UserProp &
  CancelClientProps &
  ApiDocumentProp &
  {
    harvest: Harvest;
    schemaLines: SemanticPropertyInfo[];
    domains: Domain[];
    renderTable: (mappings: MappingLineFull[], filledMappings: MappingLineFull[], lineRenderer: LineRenderer ) => JSX.Element;
  };

export function MapperView(props: Props): JSX.Element {
  const [importedJson, setImportedJson] = React.useState(null as null|Mapping);
  const [metadata, setMetadata] = React.useState(undefined as undefined|unknown[]);
  const [metadataSchema, setMetadataSchema] = React.useState(null as Field|null);
  const [allMetadataSelectors, setAllMetadataSelectors] = React.useState([] as string[]);
  const [usedMetadataSelectors, setUsedMetadataSelectors] = React.useState([] as string[]);
  const [metadataSelectors, setMetadataSelectors] = React.useState([] as string[]);
  const [mappingId, setMappingId] = React.useState(null as null|string);
  const [mappings, setMappings] = React.useState([] as MappingLineFull[]);
  const [originalMappings, setOriginalMappings] = React.useState([] as  MappingLineFull[]);
  const [metadataInfoRequest, setMetadataInfoRequest] = React.useState(noMIRequest);

  const filterMappings: (ms: MappingLineFull[]) => MappingLineFull[] = ms => ms.filter(line => line.metadataItem !== "");
  const filledMappings = React.useMemo(() => filterMappings(mappings), [mappings]);

  //React.useEffect(() => console.log(metadataInfoRequest), [metadataInfoRequest]);
  //React.useEffect(() => console.log(originalMappings), [originalMappings]);
  //React.useEffect(() => console.log(filledMappings), [filledMappings]);
  //React.useEffect(() => console.log(usedMetadataSelectors), [usedMetadataSelectors]);
  //React.useEffect(() => console.log({mappingId}), [mappingId]);

  async function getMappings(): R.ResultP<MappingLine[], Response|string> {
    const mappingsIdsR = await mappingsApi.listMappings(props.userData, props.apiDocument.id);
    //console.log(`Available mappings for apiId=${props.apiDocument.id}:`);
    //console.log(mappingsIds);
    if (R.isError(mappingsIdsR)) {
      return R.error(mappingsIdsR.error);
    }
    const mappingsIds = mappingsIdsR.ok;
    if (mappingsIds.length > 0) {
      const mappingId = mappingsIds[0]; // Currently just one mapping per API
      setMappingId(mappingId);
      const mappingR = await mappingsApi.getMapping(props.userData, props.apiDocument.id, mappingId);
      if (R.isError(mappingR)) {
        return R.error(mappingR.error);
      }
      const mapping = mappingR.ok;
      return R.ok(mapping.lines);
    } else {
      setMappingId(null);
      return R.ok([]);
    }
  }

  //// Hooks {{{1 ////

  // Fill-in document from importedJson
  React.useEffect(
    () => {
      if (importedJson) {
        const lines = importedJson.lines;
        const noOfItems: number = props.domains.reduce(
          (res, domain) => res + selectMappingsByDomain(lines, domain).length,
          0
        );
        if (noOfItems === 0) {
          props.onError("No mappings found in the imported file, maybe a wrong one?");
        } else {
          const filledSaMappings = mappings.map(
            ml => {
              const metadataItem = getMetadataItem(lines, ml.schemaItem);
              return {
                ...ml,
                metadataItem: metadataItem ? metadataItem : ml.metadataItem,
              };
            }
          );
          setMappings(filledSaMappings);
          setImportedJson(null);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [importedJson]
  );

  // Get metadata at start
  React.useEffect(
    () => {
      props.callAsync(
        () => harvestsApi.getMetadata(props.apiDocument.id, props.harvest.id, props.userData),
        result => {
          setMetadata(result.map(m => m.metadata));
        }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Get metadata schema at start
  React.useEffect(
    () => {
      props.callAsync(
        () => harvestsApi.getMetadataSchema(props.apiDocument.id, props.harvest.id, props.userData),
        resp => {
          setMetadataSchema(resp);
          setAllMetadataSelectors(getJsonPaths(resp));
        }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Update the cancel function when needed
  React.useEffect(
    () => props.setCanCancelFn(() => () => _.isEqual(originalMappings, filledMappings)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [originalMappings, filledMappings]
  );

  // Update lines if props.schemaItems change
  React.useEffect(
    () => {
      if (props.schemaLines.length > 0) {
        props.callAsync(
          () => getMappings(),
          storedMappings => {
            const newMappings =
              props.schemaLines.map(schemaItem => ({
                schemaItem,
                metadataItem:
                  getMetadataItem(storedMappings, schemaItem) || "",
              }));
            setMappings(newMappings);
            setOriginalMappings(filterMappings(newMappings));
          }
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.schemaLines]
  );

  // Update metadataSelectors to exclude used selectors
  React.useEffect(
    () => {
      const usedSelectors: string[] =
        mappings.reduce(
          (res, m) => m.metadataItem.length > 0 ? [...res, m.metadataItem] : res,
          [] as string[]
        );
      setUsedMetadataSelectors(usedSelectors);
      setMetadataSelectors(_.difference(allMetadataSelectors, usedSelectors));
    },
    [mappings, allMetadataSelectors]
  );

  // Update preview modal
  React.useEffect(
    () => {
      const schemaView =
        metadataInfoRequest !== noMIRequest && metadataSchema ?
          <SchemaView
            title={`Mapping of ${metadataInfoRequest.schemaItemUri}`}
            metadataSchema={metadataSchema}
            selectedFieldJsonPath={metadataInfoRequest.metadataFieldJsonPath}
            usedJsonPaths={usedMetadataSelectors}
            onSelectedFieldJsonPathChange={newJsonPath => {
              const { schemaItemUri } = metadataInfoRequest;
              metadataFieldChanged(schemaItemUri, newJsonPath);
              setMetadataInfoRequest(noMIRequest);
            }}
          />
        : null;

        props.onPreview(schemaView, () => setMetadataInfoRequest(noMIRequest));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [metadataInfoRequest, metadataSchema, usedMetadataSelectors]
  );

  //// Routines ////

  async function exportMappings(): Promise<void> {

    async function doExport(): Promise<void> {
      if (!mappingId) {
        props.onError("Mapping id is missing, something went wront");
      } else {
        props.callAsync(
          () => mappingsApi.getMapping(props.userData, props.apiDocument.id, mappingId),
          result => {
            const blob = new Blob([JSON.stringify(result, null, 2)], { type: jsonMime });
            const fname = props.apiDocument.doc.info.title + ".mappings.json";
            saveAs(blob, fname);
          }
        );
      }
    }

    props.callAsync(
      saveMappings,
      doExport
    );
  }

  function exportSchema(): void {
    if (!metadataSchema) {
      props.onError("Metadata schema is missing, something went wront");
    } else {
      const blob = new Blob([JSON.stringify(metadataSchema, null, 2)], { type: jsonMime });
      const fname = props.apiDocument.doc.info.title + ".metadata-schema.json";
      saveAs(blob, fname);
    }
  }

  function metadataFieldChanged(schemaItemUri: string, newJsonPath: string|null) {
    //console.log("metadataFieldChanged");
    //console.log({index, newJsonPath});
    setMappings(oldMappings =>
      oldMappings.map(mapping =>
        mapping.schemaItem.uri === schemaItemUri
          ? {
              ...mapping,
              metadataItem: newJsonPath || "",
            }
          : mapping
      )
    );
  }

  async function showTermInfo(property: SemanticPropertyInfo): Promise<void> {
    props.callAsync(
      () => rdfApi.getTermInfo(property.range, props.userData),
      termInfo => {
        if (termInfo) {
          props.onPreview(
            <PropertiesTable
              property={property}
              properties={termInfo}
            />
          );
        }
      }
    );
  }

  async function saveMappings(): R.ResultP<void, HttpError> {
    const lines: MappingLine[] = filledMappings.map(l => {
      const schemaItem: SemanticProperty = { // Stripping SemanticPropertyInfo into SemanticProperty
        uri: l.schemaItem.uri,
        name: l.schemaItem.name,
        domain: l.schemaItem.domain,
        parentProperty: l.schemaItem.parentProperty,
      };
      return {
        ...l,
        schemaItem
      };
    });
    const mapping: PostMappingBody = { lines };

    function finalize(): void {
      props.onLoad(false);
      setOriginalMappings(filledMappings);
    }

    props.onLoad(true);
    if (mappingId) {
       //update mapping
      const resultR = await mappingsApi.putMapping(props.userData, props.apiDocument.id, mappingId, mapping);
      return R.either(
        resultR,
        () => {
          finalize();
          return R.ok(void(0));
        },
        R.error
      );
    } else {
      //post new mapping
      const mappingIdR = await mappingsApi.postMapping(props.userData, props.apiDocument.id, mapping);
      return R.either(
        mappingIdR,
        mappingId => {
          setMappingId(mappingId);
          finalize();
          return R.ok(void(0));
        },
        R.error
      );
    }
  }

  ///// Rendering /////

  function renderEndorsement(e: Endorsement): React.ReactElement {
    const css: SxProps = {
      width: 20,
      textAlign: "center",
      fontWeight: "bold",
      color: "#ffffff",
      borderRadius: 8,
      p: 0,
      mr: 1,
    };
    const mandatoryCss: SxProps = {
      ...css,
      backgroundColor: RED[500],
    };
    const recommendedCss: SxProps = {
      ...css,
      backgroundColor: ORANGE[500],
    };
    const optionalCss: SxProps = {
      ...css,
      backgroundColor: GREEN[500],
    };

    return matchSwitch(e, {
      [Endorsement.MANDATORY]: () => (
        <Tooltip title="Mandatory">
          <Box sx={mandatoryCss}>M</Box>
        </Tooltip>
      ),
      [Endorsement.RECOMMENDED]: () => (
        <Tooltip title="Recommended">
          <Box sx={recommendedCss}>R</Box>
        </Tooltip>
      ),
      [Endorsement.OPTIONAL]: () => (
        <Tooltip title="Optional">
          <Box sx={optionalCss}>O</Box>
        </Tooltip>
      ),
    });
  }

  function renderSchemaItem(property: SemanticPropertyInfo): React.ReactElement {
    return (
      <Stack direction="column" alignItems="start">
        <Stack direction="row" alignItems="center">
          {property.endorsement ? renderEndorsement(property.endorsement) : <></>}
          {property.name}
          <IconButton size="small" onClick={() => showTermInfo(property)}>
            <InfoIcon />
          </IconButton>
        </Stack>
        <Box component="span" sx={{fontStyle: "italic", fontSize: "90%"}}>
          {property.definition}
        </Box>
      </Stack>
    );
  }

  function renderMetadataInput(schemaItemUri: string, jsonPath: string | null): React.ReactElement {
    return (
      <FieldSelector
        label="Metadata field"
        style={{ width: "100%" }}
        value={jsonPath}
        jsonPathItems={metadataSelectors}
        metadata={metadata}
        onValueChanged={jsonPath => metadataFieldChanged(schemaItemUri, jsonPath)}
        onSchemaViewRequested={jsonPath =>
          setMetadataInfoRequest({
            metadataFieldJsonPath: jsonPath || "",
            schemaItemUri,
          })
        }
        onPreview={props.onPreview}
      />
    );
  }

  function renderLine(mappingLine: MappingLineFull, css: SxProps): JSX.Element {
    const isSubproperty = mappingLine.schemaItem.parentProperty !== undefined;
    return (
      <TableRow key={mappingLine.schemaItem.uri}>
        <TableCell sx={{...css, width: "40%", pl: isSubproperty ? 6 : 2}}>{renderSchemaItem(mappingLine.schemaItem)}</TableCell>
        <TableCell sx={{...css, pl: isSubproperty ? 6 : 2}}>
          {renderMetadataInput(mappingLine.schemaItem.uri, mappingLine.metadataItem)}
        </TableCell>
      </TableRow>
    );
  }


  function renderActions(): JSX.Element {
    return (
      <Stack direction="row" justifyContent="center" spacing={6}>
        <Button
          variant="contained"
          size="large"
          startIcon={<FinishedIcon />}
          disabled={filledMappings.length === 0}
          onClick={async () => {
            P.pipe
              (await saveMappings())
              .thru(R.errorDo(props.onError))
              .value();
          }}
        >
          Save Mappings
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          startIcon={<ExportIcon />}
          onClick={exportMappings}
        >
          Export mappings
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          startIcon={<ExportIcon />}
          onClick={exportSchema}
        >
          Export metadata schema
        </Button>
        <Button
          variant="contained"
          color="warning"
          size="large"
          startIcon={<CloseIcon />}
          onClick={props.requestCancel}
        >
          Close
        </Button>
      </Stack>
    );
  }

  return (
    <Stack direction="column" spacing={2} sx={{mt: 2}}>
      <Importer
        {...props}
        title="Import mappings"
        onImported={setImportedJson}
      />
      {props.renderTable(mappings, filledMappings, renderLine)}
      {renderActions()}
    </Stack>
  );
}
