import React from "react";
import type { SxProps } from "@mui/system";
import {
  IconButton,
  Stack,
  TableCell,
  TableRow
} from "@mui/material";
import {
  KeyboardArrowUp as CloseIcon,
  KeyboardArrowDown as OpenIcon
} from "@mui/icons-material";

interface Props {
  colSpan: number;
  heading: string|JSX.Element;
  headingCss: SxProps;
  children: JSX.Element | JSX.Element[];
}

export function FoldableTR(props: Props): React.FunctionComponentElement<Props> {
  const [show, setShow] = React.useState(false);

  return (
    <>
      <TableRow>
        <TableCell sx={props.headingCss} colSpan={props.colSpan}>
          <Stack direction="row" alignItems="center">
            {props.heading}
            <IconButton size="small" onClick={() => setShow(!show)}>
              {show ? <CloseIcon /> : <OpenIcon />}
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>
      {show ? props.children : <></>}
    </>
  );
}
