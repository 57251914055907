import * as R from "result-async";
import { post, put, get, ResponseType } from "@app/Api/http";
import { getConfig } from "@app/config";
import type { UserData } from "../Models/user";
import type { Mapping, PostMappingBody } from "@fair-space/core/types/mapping";

export type { Mapping };

const config = getConfig();

function mkMappingsUrl(apiId: string): string {
  return `${config.SERVER_URL}/apis/${apiId}/mapping`;
}

export function postMapping(userData: UserData, apiId: string, mapping: PostMappingBody): R.ResultP<string, Response|string> {
  return post(mkMappingsUrl(apiId), userData, ResponseType.JSON, mapping);
}

export function putMapping(userData: UserData, apiId: string, mappingId: string, mapping: PostMappingBody): R.ResultP<void, Response|string> {
  return put(`${mkMappingsUrl(apiId)}/${mappingId}`, userData, mapping);
}

export function listMappings(userData: UserData, apiId: string): R.ResultP<string[], Response|string> {
  return get(mkMappingsUrl(apiId), userData);
}

export function getMapping(userData: UserData, apiId: string, mappingId: string): R.ResultP<Mapping, Response|string> {
  return get(`${mkMappingsUrl(apiId)}/${mappingId}`, userData);
}
