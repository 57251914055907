import React from "react";
import type {
  ApiDocumentProp,
  CallAsyncProp,
  CancelClientProps,
  IndicationProps,
  PreviewProp,
  TryCancelProp,
  UserProp,
} from "@app/Components/definitions";
import {
  PageHeading,
} from "@app/Components/UIComponents";
import {
  Box,
  Stack,
  Tab,
  Tabs,
} from "@mui/material";
import { RepoInformation } from "./RepoInformation";
import { ApiDescriptionForm } from "./ApiDescriptionForm";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{pt: 1, pr: 1}}>
          {children}
        </Box>
      )}
    </div>
  );
}

type Props =
  IndicationProps &
  CallAsyncProp &
  PreviewProp &
  UserProp &
  TryCancelProp &
  CancelClientProps &
  Partial<ApiDocumentProp> &
  {
    onApiDocumentSaved: (apiId: string) => void
  };

export function RepoDescription(props: Props): JSX.Element {
  const [tabNo, setTabNo] = React.useState(0);
  // Using the function in a state needs to be wrapped: https://medium.com/swlh/how-to-store-a-function-with-the-usestate-hook-in-react-8a88dd4eede1
  const [apiDescriptionCanCancelFn, setApiDescriptionCanCancelFn] = React.useState(null as null | (() => () => boolean));
  const [repoInformationCanCancelFn, setRepoInformationCanCancelFn] = React.useState(null as null | (() => () => boolean));
  const [canCancelFn, setCanCancelFn] = React.useState(null as null | (() => () => boolean));

  // Once the both canCancel functions are in place, combine them
  React.useEffect(
    () => {
      const canCancelFn1: null | (() => boolean) =
        apiDescriptionCanCancelFn !== null && repoInformationCanCancelFn !== null ?
          () => apiDescriptionCanCancelFn() && repoInformationCanCancelFn()
        : apiDescriptionCanCancelFn !== null ?
          apiDescriptionCanCancelFn
        : repoInformationCanCancelFn !== null ?
          repoInformationCanCancelFn
        : null;
      if (canCancelFn1 !== null) {
        setCanCancelFn(() => canCancelFn1);
        props.setCanCancelFn(() => canCancelFn1);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apiDescriptionCanCancelFn, repoInformationCanCancelFn]
  );

  React.useEffect(
    () => {
      if (canCancelFn !== null) {
        props.setCanCancelFn(() => canCancelFn);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [canCancelFn]
  );

  // Check unsaved changes when switching Tabs
  function switchTab(newTabNo: number): void {
    if (canCancelFn) {
      props.tryCancel(canCancelFn(), () => setTabNo(newTabNo));
    } else {
      props.onError("Internal error: canCancelFn is not set");
    }
  }

  return (
    <Stack direction="column" spacing={2} sx={{ height: "100%" }}>
      <PageHeading
        title={
          <Box component="span">
            Repository description:
            <Box component="span" sx={{fontWeight: "bolder"}}>{props.apiDocument?.doc.info.title || ""}</Box>
          </Box>
        }
        onCancelled={props.requestCancel}
      />
      <Tabs value={tabNo} onChange={(_, newVal) => switchTab(newVal)}>
        <Tab label="API Description" id="tab-0" />
        <Tab label={props.apiDocument ? "Metadata" : "Metadata (Description needs to be saved first!)"} id="tab-1"
          disabled={!props.apiDocument}
        />
      </Tabs>
      <TabPanel value={tabNo} index={0}>
        <ApiDescriptionForm
          {...props}
          onApiDocumentSaved={props.onApiDocumentSaved}
          setCanCancelFn={setApiDescriptionCanCancelFn}
        />
      </TabPanel>
      <TabPanel value={tabNo} index={1}>
        {props.apiDocument ?
          <RepoInformation
            {...props}
            apiDocument={props.apiDocument} // Must be restated, as it is optional from the parent, but mandatory here
            setCanCancelFn={setRepoInformationCanCancelFn}
          />
        : <div>Oops, apiDocument is empty, I should not be here...</div>
        }
      </TabPanel>
    </Stack>
  );
}
