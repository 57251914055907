/* eslint-disable react/display-name */
import _ from "lodash";
import React from "react";
import { matchSwitch } from "@babakness/exhaustive-type-checking";
import { v1 as uuid } from "uuid";
import type {
  ArrayField,
  AtomicField,
  Field,
  FieldStat,
  ObjectField,
} from "@fair-space/core/analyser";
import {
  FieldTypesEnum,
  getObjectPropertyNames,
  getStatsOfProperty,
} from "@fair-space/core/analyser";
import type { SxProps } from "@mui/system";
import {
  Box,
  Paper,
  Stack
} from "@mui/material";
import {
  yellow as YELLOW,
  lightGreen as GREEN,
  grey as GREY
} from "@mui/material/colors";


const typeFieldCss: SxProps = {
  color: GREY[600],
};


interface Props {
  title: string;
  metadataSchema: Field;
  selectedFieldJsonPath: string|null;
  usedJsonPaths?: string[];
  onSelectedFieldJsonPathChange: (newFieldJsonPath: string) => void;
}

export function SchemaView(props: Props): JSX.Element {
  const [focusedFieldJsonPath, setFocusedFieldJsonPath] = React.useState("");

  interface RenderAcc {
    fields: JSX.Element[];
  }

  function isUsed(jsonPath: string): boolean {
    return props.usedJsonPaths !== undefined && props.usedJsonPaths.includes(jsonPath);
  }

  function blockCss(jsonPath: string): SxProps {
    return {
      pl: 2,
      pr: 1,
      borderLeft: "1px solid blue",
      bgcolor: "background.paper",
      cursor: isUsed(jsonPath) ? "not-allowed" : "pointer",
      "& *": {
        cursor: "pointer"
      },
      ...!isUsed(jsonPath) ? {
        "&:hover": {
          bgcolor: "action.selected"
        }}
        :{},
      ...props.selectedFieldJsonPath === jsonPath ?
           { background: YELLOW[500] }
         : isUsed(jsonPath) ?
           { background: GREEN[200] }
         : {}
    };
  }

  function handleSelectedFieldChange(ev: React.MouseEvent, jsonPath: string) {
    if (!isUsed(jsonPath)) {
      props.onSelectedFieldJsonPathChange(jsonPath);
    }
    ev.stopPropagation();
  }

  function handleMouseOver(ev: React.MouseEvent, jsonPath: string) {
    if (!isUsed(jsonPath)) {
      setFocusedFieldJsonPath(jsonPath);
    }
    ev.stopPropagation();
  }

  function renderAtomicField({fields}: RenderAcc, field: AtomicField, fieldStats?: FieldStat): RenderAcc {
    return {
      fields: [
        ...fields,
        <Box key={uuid()}
          sx={blockCss(field.jsonPath)}
          onClick={ev => handleSelectedFieldChange(ev, field.jsonPath)}
          onMouseOver={ev => handleMouseOver(ev, field.jsonPath)}
        >
          {field.name ? `${field.name}:` : ""}
          <Box component="span" sx={typeFieldCss}>
            {field.type}
            {fieldStats && (fieldStats.relativeOccurency !== 1.0) ?
              `(${fieldStats.occurencies}/${fieldStats.relativeOccurency})`
            : ""}
          </Box>
        </Box>
      ],
    };
  }

  function renderArrayField({fields}: RenderAcc, field: ArrayField): RenderAcc {
    const stats = field.itemsStats;
    const itemsRender =
      stats ?
        <Stack direction="row" justifyItems="stretch">
          {stats.map(stat => renderFieldRec({ fields: [] }, stat.field, stat).fields)}
        </Stack>
      :
        {
          fields: <Box component="span" sx={typeFieldCss}>empty</Box>,
        };
    return {
      fields: [
        ...fields,
        <Box key={uuid()}
          sx={blockCss(field.jsonPath)}
          onClick={ev => handleSelectedFieldChange(ev, field.jsonPath)}
          onMouseOver={ev => handleMouseOver(ev, field.jsonPath)}
        >
          {`${field.name ? `${field.name}: ` : ""}`}
          <Box component="span" sx={typeFieldCss}>{"["}</Box>
          {itemsRender}
          <Box component="span" sx={typeFieldCss}>{"]"}</Box>
        </Box>
      ],
    };
  }

  function renderObjectField({fields}: RenderAcc, field: ObjectField): RenderAcc {
    const pNames = getObjectPropertyNames(field);
    const propertiesRender =
      <Box>
        {pNames.map(pName =>
          <Stack key={uuid()} direction="row" justifyItems="stretch">
            {getStatsOfProperty(field, pName)
              .map(stat => renderFieldRec({fields: []}, stat.field, stat).fields)}
          </Stack>
        )}
      </Box>;

    return {
      fields: [
        ...fields,
        <Box key={uuid()}
          sx={blockCss(field.jsonPath)}
          onClick={ev => handleSelectedFieldChange(ev, field.jsonPath)}
          onMouseOver={ev => handleMouseOver(ev, field.jsonPath)}
        >
          {`${field.name ? `${field.name}: ` : ""}`}
          <Box component="span" sx={typeFieldCss}>{"{"}</Box>
          {propertiesRender}
          <Box component="span" sx={typeFieldCss}>{"}"}</Box>
        </Box>
      ],
    };
  }

  function renderFieldRec(renderAcc: RenderAcc, field: Field, fieldStats?: FieldStat): RenderAcc {
    return (
      matchSwitch(field.type, {
          [FieldTypesEnum.STRING]: () => renderAtomicField(renderAcc, field, fieldStats),
          [FieldTypesEnum.URL]: () => renderAtomicField(renderAcc, field, fieldStats),
          [FieldTypesEnum.NUMBER]: () => renderAtomicField(renderAcc, field, fieldStats),
          [FieldTypesEnum.BOOLEAN]: () => renderAtomicField(renderAcc, field, fieldStats),
          [FieldTypesEnum.ARRAY]: () => renderArrayField(renderAcc, field as ArrayField),
          [FieldTypesEnum.OBJECT]: () => renderObjectField(renderAcc, field as ObjectField),
          [FieldTypesEnum.NULL]: () => renderAtomicField(renderAcc, field),
          [FieldTypesEnum.UNDEFINED]: () => renderAtomicField(renderAcc, field),
      })
    );
  }

  function renderBreadCrumbs(): JSX.Element {
    return (
      <Paper elevation={3} sx={{backgroundColor: GREEN[200], p: 1}}>
        {focusedFieldJsonPath || ""}
      </Paper>
    );
  }

  function renderTitle(): JSX.Element {
    return (
      <h2>
        {props.title}
      </h2>
    );
  }

  return (
    <Box sx={{height: (theme) => `calc(100% - ${theme.spacing(6)})`}}>
      {renderTitle()}
      {renderBreadCrumbs()}
      <Paper elevation={3} sx={{height: "100%", overflow: "auto", mt: 1}}>
        {renderFieldRec({fields: []}, props.metadataSchema).fields}
      </Paper>
    </Box>
  );
}

