/* eslint-disable react/display-name */
import React from "react";
import {
  IconButton,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  ListItemIcon,
} from "@mui/material";
import {
  AccountCircle as AccountCircleIcon,
  Logout as LogoutIcon
} from "@mui/icons-material";
import { UserProp } from "@app/Components/definitions";

type Props =  UserProp & {
  onAccount: () => void;
  onLogout: () => void;
}

// This whole component is based on this documentation:
// https://material-ui.com/components/menus/#menulist-composition
export function AccountBadge(props: Props): React.FunctionComponentElement<Props> {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  function handleToggle() {
    setOpen((prevOpen) => !prevOpen);
  }

  function handleClose(event: unknown) {
    if (anchorRef.current && anchorRef.current.contains((event as MouseEvent).target as HTMLElement)) {
      return;
    }
    setOpen(false);
  }

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // Return focus to the button when we transitioned from !open -> open.
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false && anchorRef.current) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  function handleLogout() {
    props.onLogout();
    setOpen(false);
  }

  return <>
    <IconButton
      ref={anchorRef}
      aria-controls={open ? "menu-list-grow" : undefined}
      aria-haspopup="true"
      onClick={handleToggle}
      size="large">
      <AccountCircleIcon />
    </IconButton>
    <Popper
      //sx={{left: "-1rem !important"}}
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}
        >
        <Paper>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList
              autoFocusItem={open}
              id="menu-list-grow"
              onKeyDown={handleListKeyDown}
            >
              <MenuItem onClick={ev => { handleClose(ev); props.onAccount(); }}>
                <ListItemIcon>
                  <AccountCircleIcon fontSize="small" />
                </ListItemIcon>
                My account
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <LogoutIcon fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Grow>
      )}
    </Popper>
  </>;
}

