import { JobInfo } from "@app/Api/harvests";

export type JobInfoRecord = {
  jobInfo: JobInfo|undefined|null;
  loading: boolean;
}

export const noJobInfo: JobInfoRecord = {
  jobInfo: null,
  loading: true,
};

export function jobPercent(jobInfo: JobInfo): number {
  const res = Math.round((jobInfo.processedItems / jobInfo.totalItems) * 1000) / 10;
  return (
    isNaN(res) ?
      0
    : res
  );
}

export function jobEta(jobInfo: JobInfo): string {
  if (!jobInfo.startedDate) {
    return "Not started, yet";
  } else {
    const startedTime = new Date(jobInfo.startedDate).getTime();
    const currentTime = (new Date()).getTime();
    const totalSpentTime = currentTime - startedTime;
    const oneItemSpentTime = totalSpentTime / jobInfo.processedItems;
    const itemsToProcess = jobInfo.totalItems - jobInfo.processedItems;
    if (itemsToProcess === 0) {
      return "Checking the number of items to process...";
    } else {
      const remainingTime = itemsToProcess * oneItemSpentTime;
      const remainingTimeSecs = Math.round(remainingTime / 1000);
      const remainingTimeStr =
        remainingTimeSecs / 3600 > 1 ?
          `${Math.round(remainingTimeSecs / 3600)} hours remaining`
        : remainingTimeSecs / 60 > 1 ?
          `${Math.round(remainingTimeSecs / 60)} minutes remaining`
        : `${remainingTimeSecs} seconds remaining`;
      const eta = new Date(currentTime + remainingTime);
      return `${eta.toLocaleString()} (${remainingTimeStr})`;
    }
  }
}
