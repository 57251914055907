import _ from "lodash";
import * as R from "result-async";
import React from "react";
import useInputFile from "use-input-file";
import {
  blue as BLUE,
} from "@mui/material/colors";
import type { CallAsyncProp } from "@app/Components/definitions";
import { FSFormGroup } from "@app/Components/UIComponents";
import {Button, Grid} from "@mui/material";

export const jsonMime = "application/json";

function importJson<T>(file: File): R.ResultP<T, string> {
  return new Promise(resolve => {
    if (file.type !== jsonMime) {
      resolve(R.error("The selected file is not JSON"));
    } else {
      const reader = new FileReader();

      reader.onload = () => {
        if (!reader.result) {
          resolve(R.error("Import failed: empty reader result"));
        } else {
          resolve(R.parseJson(reader.result as string));
        }
      };
      reader.onerror = () => resolve(R.error(reader.error?.message || "Failed reading input file"));

      reader.readAsText(file);
    }
  });
}
export type Props<T> =
  CallAsyncProp &
  {
    title: string;
    onImported: (data: T) => void;
  }

export function Importer<T>(props: Props<T>): JSX.Element {
  const { ref: inputFileRef, files: importFiles } = useInputFile({ options: { accept: jsonMime, multiple: false }});

  function importData(file: File): void {
    props.callAsync(
      () => importJson<T>(file),
      data => {
        props.onImported(data);
      }
    );
  }

  return (
    <FSFormGroup heading={props.title} sx={{backgroundColor: BLUE[50]}} elevation={1}>
      <Grid item sm={12}>
        <input type="file" ref={inputFileRef} />
        <Button
          variant="outlined"
          color="secondary"
          sx={{ml: 2}}
          disabled={!importFiles}
          onClick={() => importData(importFiles![0])}
        >
          Import
        </Button>
      </Grid>
    </FSFormGroup>
  );
}


