import _ from "lodash";
import React from "react";
import { Box } from "@mui/material";

interface Props {
  json: unknown;
}

export function JsonPreview(props: Props): JSX.Element {
  return (
    <Box>
      {_.isArray(props.json) ?
        <h2>{(props.json as Array<unknown>).length} items</h2>
        : <></>
      }
      <pre>
        <code>
          {JSON.stringify(props.json, null, 2)}
        </code>
      </pre>
    </Box>
  );
}
