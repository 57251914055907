import _ from "lodash";
import * as R from "result-async";
import React from "react";
import { saveAs } from "file-saver";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  Stack,
  IconButton,
  Box,
  TextField,
  Button,
} from "@mui/material";
import {
  Help as InfoIcon,
  Done as FinishedIcon,
  FileDownload as ExportIcon,
  Cancel as CloseIcon,
} from "@mui/icons-material";
import {
  grey as GREY,
} from "@mui/material/colors";
import type {
  SetFn,
  SemanticProperty,
  SemanticPropertyInfo,
  MappingLine,
  Mapping,
  MappingLineFull,
} from "@app/Models/mapping";
import * as rdfApi from "@app/Api/rdf";
import {
  ApiDocumentProp,
  IndicationProps,
  PreviewProp,
  UserProp,
  CancelClientProps,
  CallAsyncProp,
} from "@app/Components/definitions";
import * as reposApi from "@app/Api/repos";
import { PropertiesTable } from "@app/Components/Mapper/PropertiesTable";
import { Importer, jsonMime } from "@app/Components/Importer";
import { type HttpError } from "@app/Api/http";

type Props =
  IndicationProps &
  CallAsyncProp &
  PreviewProp &
  UserProp &
  CancelClientProps &
  ApiDocumentProp;

export function RepoInformation(props: Props): JSX.Element {
  const [importedJson, setImportedJson] = React.useState(null as null|Mapping);
  const [catalogId, setCatalogId] = React.useState(null as null|string);
  const [caMappings, setCaMappings] = React.useState([] as MappingLineFull[]);
  const filledMappings = React.useMemo(() => caMappings.filter(line => line.metadataItem !== ""), [caMappings]);
  const [originalMappings, setOriginalMappings] = React.useState(null as null | MappingLineFull[]);

  async function getStoredMappings(): R.ResultP<MappingLine[], HttpError> {
    const catalogR = await reposApi.getCatalog(props.apiDocument.id, props.userData);
    if (R.isError(catalogR)) {
      return R.error(catalogR.error);
    } else {
      const catalog = catalogR.ok;
      if (catalog) {
        setCatalogId(catalog.id);
        const caMappings = catalog.lines;
        return R.ok(caMappings);
      } else {
        setCatalogId(null);
        return R.ok([]);
      }
    }
  }

  //// Hooks ////

  // Fill-in document from importedJson
  React.useEffect(
    () => {
      if (importedJson) {
        const impCa = importedJson.lines;
        if (!impCa || impCa.length === 0) {
          props.onError("No mappings found in the imported file, maybe a wrong one?");
        } else {
          const filledCaMappings = caMappings.map(
            ml => {
              const metadataItem = getMetadataItem(impCa, ml.schemaItem);
              return {
                ...ml,
                metadataItem: metadataItem ? metadataItem : ml.metadataItem,
              };
            }
          );
          if (_.isEqual(caMappings, filledCaMappings)) {
            props.onError("No mappings found in the imported file, maybe a wrong one?");
          } else {
            setCaMappings(filledCaMappings);
          }
          setImportedJson(null);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [importedJson]
  );

  // Load and create mappings at start
  React.useEffect(() => {
    props.onLoad(true);
    Promise.all([
      rdfApi.getDcatCatalogProperties(props.userData),
      getStoredMappings()
    ]).then(
      ([caPropertiesR, storedMappingsR ]) => {
        props.onLoad(false);
        if (R.isError(caPropertiesR)) {
          props.onError(caPropertiesR.error);
        } else if (R.isError(storedMappingsR)) {
          props.onError(storedMappingsR.error);
        } else {
          const caSchemaLines = _.sortBy(caPropertiesR.ok, l => l.name);
          const mappings = [
            ...caSchemaLines.map(schemaItem => ({
              schemaItem,
              metadataItem:
                getMetadataItem(storedMappingsR.ok, schemaItem) || "",
            })),
          ];
          setCaMappings(mappings);
          setOriginalMappings(mappings.filter(line => line.metadataItem !== ""));
        }
      },
      props.onError
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set the canCancel function in the parent
  React.useEffect(
    () => props.setCanCancelFn(() => () => _.isEqual(originalMappings, filledMappings)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [originalMappings, filledMappings]
  );

  //// Routines ////

  function exportMappings(): void {

    async function doExport(): Promise<void> {
      if (!catalogId) {
        props.onError("Catalog id is missing, something went wront");
      } else {
        props.callAsync(
          () => reposApi.getCatalog(props.apiDocument.id, props.userData),
          result => {
            const blob = new Blob([JSON.stringify(result, null, 2)], { type: jsonMime });
            const fname = props.apiDocument.doc.info.title + ".metadata.json";
            saveAs(blob, fname);
          }
        );
      }
    }

    props.callAsync(
      saveMappings,
      doExport,
    );
  }

function getMetadataItem(mappingLines: MappingLine[], schemaItem: SemanticPropertyInfo): string|undefined {
  // used also in importing, so make safe
  try {
    const res = mappingLines.find(l => l.schemaItem.uri === schemaItem.range && l.schemaItem.domain === schemaItem.domain)?.metadataItem;
    return res;
  } catch(_err) { return undefined; }
}

  function metadataFieldChanged(setFn: SetFn, schemaItemUri: string, newValue: string|null) {
    //console.log("metadataFieldChanged");
    //console.log({index, newJsonPath});
    setFn(oldMappings =>
      oldMappings.map(mapping =>
        mapping.schemaItem.uri === schemaItemUri
          ? {
              ...mapping,
              metadataItem: newValue || "",
            }
          : mapping
      )
    );
  }

  function showTermInfo(property: SemanticPropertyInfo): void {
    props.callAsync(
      () => rdfApi.getTermInfo(property.range, props.userData),
      termInfo => {
        props.onLoad(false);
        if (termInfo) {
          props.onPreview(
            <PropertiesTable
              property={property}
              properties={termInfo}
            />
          );
        }
      }
    );
  }

  async function saveMappings(): R.ResultP<void, HttpError> {
    const lines: MappingLine[] = filledMappings.map(l => {
      const schemaItem: SemanticProperty = { // Stripping SemanticPropertyInfo into SemanticProperty
        uri: l.schemaItem.range,
        name: l.schemaItem.name,
        domain: l.schemaItem.domain,
        parentProperty: l.schemaItem.parentProperty,
      };
      return {
        ...l,
        schemaItem
      };
    });

    function finalize(): void {
      props.onLoad(false);
      setOriginalMappings(filledMappings);
    }

    props.onLoad(true);
    if (catalogId) {
      // update catalog
      const resultR = await reposApi.putCatalog(props.apiDocument.id, catalogId, props.userData, { lines });
      return R.either(
        resultR,
        () => {
          finalize();
          return R.ok(void(0));
        },
        R.error
      );
    } else {
      // post new catalog
      const catalogIdR = await reposApi.postCatalog(props.apiDocument.id, props.userData, { lines });
      return R.either(
        catalogIdR,
        catalogId => {
          setCatalogId(catalogId);
          finalize();
          return R.ok(void(0));
        },
        R.error
      );
    }
  }

  //// Rendering ////

  function renderSchemaItem(property: SemanticPropertyInfo): React.ReactElement {
    return (
      <Stack direction="column" alignItems="start">
        <Stack direction="row" alignItems="center">
          {property.name}
          <IconButton size="small" onClick={() => showTermInfo(property)}>
            <InfoIcon />
          </IconButton>
        </Stack>
        <Box component="span" sx={{fontStyle: "italic", fontSize: "90%"}}>
          {property.definition}
        </Box>
      </Stack>
    );
  }

  function renderMetadataInput(setFn: SetFn, schemaItemUri: string, value: string | null): React.ReactElement {
    return (
      <TextField
        fullWidth
        variant="standard"
        value={value}
        onChange={ev => metadataFieldChanged(setFn, schemaItemUri, ev.target.value)}
      />
    );
  }

  function renderLine(mappingLine: MappingLineFull, setFn: SetFn): React.ReactElement {
    const isSubproperty = mappingLine.schemaItem.parentProperty !== undefined;
    return (
      <TableRow key={mappingLine.schemaItem.uri}>
        <TableCell sx={{pl: isSubproperty ? 6 : 2}}>{renderSchemaItem(mappingLine.schemaItem)}</TableCell>
        <TableCell sx={{pl: isSubproperty ? 6 : 2}}>
          {renderMetadataInput(setFn, mappingLine.schemaItem.uri, mappingLine.metadataItem)}
        </TableCell>
      </TableRow>
    );
  }

  function renderCaTable(): JSX.Element {
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{backgroundColor: GREY[200]}}>
              <TableCell>
                <a href="https://www.w3.org/TR/vocab-dcat-2" target="_blank" rel="noreferrer">DCAT2</a> Catalog property
              </TableCell>
              <TableCell>
                Value
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {caMappings.map(l => renderLine(l, setCaMappings))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  function renderActions(): JSX.Element {
    return (
      <Stack direction="row" justifyContent="center" spacing={6}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={<FinishedIcon />}
          onClick={saveMappings}
        >
          Save Metadata
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          startIcon={<ExportIcon />}
          onClick={exportMappings}
        >
          Export metadata
        </Button>
        <Button
          variant="contained"
          color="warning"
          size="large"
          startIcon={<CloseIcon />}
          onClick={props.requestCancel}
        >
          Close
        </Button>
      </Stack>
    );
  }

  return (
    <Stack direction="column" spacing={2}>
      <Importer
        {...props}
        title="Import repository metadata"
        onImported={setImportedJson}
      />
      {renderCaTable()}
      {renderActions()}
    </Stack>
  );
}
