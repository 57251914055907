import _ from "lodash";
import * as R from "result-async";
import { get, post, del, ResponseType, HttpError } from "@app/Api/http";
import type {
  Harvest,
  JobInfo,
  Metadata,
  Conversion,
  ConvertedMetadata,
  Publication,
  PublishedMetadata,
  ApiDocument,
} from "@fair-space/core/types";
import { JobStatus } from "@fair-space/core/types";
import { getConfig } from "@app/config";
import type { UserData } from "@app/Models/user";
import type { Field } from "@fair-space/core/analyser";

const config = getConfig();

export type { Harvest, JobInfo, Metadata, Conversion, Publication };
export { JobStatus };

// Harvest {{{1

function mkHarvestsUrl(apiId: string): string {
  return `${config.SERVER_URL}/apis/${apiId}/harvest`;
}

function mkHarvestUrl(apiId: string, harvestId: string): string {
  return `${mkHarvestsUrl(apiId)}/${harvestId}`;
}

export interface ProbeResponse {
  response: {
    status: number;
    statusText: string;
    data: unknown;
  }
}

export function getAllIds(apiDoc: ApiDocument, secCredId: string|undefined, userData: UserData): R.ResultP<ProbeResponse, HttpError> {
  return post(`${mkHarvestsUrl(apiDoc.id)}/start?testMode=getAllIds`, userData, ResponseType.JSON, {
    harvestingConstraints: apiDoc.harvestingConstraints,
    securityCredentialsId: secCredId,
  });
}

export function getOneMetadata(apiDoc: ApiDocument, secCredId: string|undefined, itemId: string, userData: UserData): R.ResultP<ProbeResponse, HttpError> {
  return post(`${mkHarvestsUrl(apiDoc.id)}/start?testMode=getOneMetadata&datasetId=${itemId}`, userData, ResponseType.JSON, {
    harvestingConstraints: apiDoc.harvestingConstraints,
    securityCredentialsId: secCredId,
  });
}

export function startHarvest(apiDoc: ApiDocument, secCredId: string|undefined, userData: UserData): R.ResultP<string, HttpError> {
  return post(`${mkHarvestsUrl(apiDoc.id)}/start`, userData, ResponseType.TEXT, {
    harvestingConstraints: apiDoc.harvestingConstraints,
    securityCredentialsId: secCredId,
  });
}

export function getHarvest(apiId: string, userData: UserData): R.ResultP<Harvest[], HttpError> {
  //const res: Harvest = {
    //id: "8ba1707aac634e5ca629a06c326e518e",
    //apiId: "473a77043b9e43219c3a2336d383fdad",
    //metadataIds: [],
    //errors: [],
    //warnings: [],
    //date: "",
  //};
  //return Promise.resolve(res);
  return get(`${mkHarvestsUrl(apiId)}?latest=true`, userData);
}

export function deleteHarvest(apiId: string, harvestId: string, userData: UserData): R.ResultP<void, HttpError> {
  return del(mkHarvestUrl(apiId, harvestId), userData);
}

export function getHarvestInfo(apiId: string, harvestId: string, userData: UserData): R.ResultP<JobInfo, HttpError> {
  const harvestInfoUrl = `${mkHarvestUrl(apiId, harvestId)}/status`;

  //const running: HarvestInfo = {
    //status: HarvestStatus.RUNNING,
    //startedDate: new Date(),
    //totalItems: 1120,
    //harvestedItems: 54,
  //};
  //const paused: HarvestInfo = {
    //status: HarvestStatus.PAUSED,
    //startedDate: new Date(),
    //totalItems: 999,
    //harvestedItems: 900,
  //};
  //const finished: HarvestInfo = {
    //status: HarvestStatus.FINISHED,
    //startedDate: new Date(),
    //finishedDate: new Date(),
    //totalItems: 654,
    //harvestedItems: 654,
  //};
  //const failed: HarvestInfo = {
    //status: HarvestStatus.FAILED,
    //startedDate: new Date(),
    //finishedDate: new Date(),
    //totalItems: 145,
    //harvestedItems: 0,
  //};

  //return apiId.includes("dfb41e84")
    //? Promise.resolve(runningG)
    //: apiId.includes("57e05826")
    //? Promise.resolve(paused)
    //: apiId.includes("351fdd43")
    //? Promise.resolve(finished)
    //: apiId.includes("b277fa72")
    //? Promise.resolve(failed)
    //: Promise.resolve(null);
  return get(harvestInfoUrl, userData);
  //return Promise.resolve(null);
}

//function mkGetMetadataStatsUrl(apiId: string, harvestId: string): string {
  //return `http://localhost:5000/apis/${apiId}/harvest/${harvestId}/metadata-stats`;
//}

//export function getMetadataStats(
  //apiId: string,
  //harvestId: string,
  //userData: UserData
//): Promise<any> {
  //return get(mkGetMetadataStatsUrl(apiId, harvestId), {
    //headers: { ...mkAuthHeader(userData) },
  //});
//}

export function getMetadata(apiId: string, harvestId: string, userData: UserData): R.ResultP<Metadata[], HttpError> {
  return get(`${mkHarvestUrl(apiId, harvestId)}/metadata`, userData);
}

export function getMetadataSchema(apiId: string, harvestId: string, userData: UserData): R.ResultP<Field, HttpError> {
  return get(`${mkHarvestUrl(apiId, harvestId)}/metadata-schema`, userData);
}

// Conversion {{{1

function mkConversionsUrl(apiId: string, harvestId: string): string {
  return `${mkHarvestUrl(apiId, harvestId)}/conversion`;
}

function mkConversionUrl(apiId: string, harvestId: string, conversionId: string): string {
  return `${mkConversionsUrl(apiId, harvestId)}/${conversionId}`;
}

export function startConversion(apiId: string, harvestId: string, mappingId: string, userData: UserData): R.ResultP<string, HttpError> {
  const url = `${mkConversionsUrl(apiId, harvestId)}/start`;
  return post(url, userData, ResponseType.TEXT, { mappingId });
}

export function getConversion(apiId: string, harvestId: string, userData: UserData): R.ResultP<Conversion[], HttpError> {
  return get(`${mkConversionsUrl(apiId, harvestId)}?latest=true`, userData);
}

export function getConversionInfo(apiId: string, harvestId: string, conversionId: string, userData: UserData): R.ResultP<JobInfo, HttpError> {
  //console.log("getConversionStatus");
  //console.log({apiId, harvestId, conversionId});
  return get(`${mkConversionUrl(apiId, harvestId, conversionId)}/status`, userData);
}

export function getConvertedMetadata(apiId: string, harvestId: string, conversionId: string, userData: UserData): R.ResultP<ConvertedMetadata[], HttpError> {
  return get(`${mkConversionUrl(apiId, harvestId, conversionId)}/converted-metadata`, userData);
}

export function deleteConversion(apiId: string, harvestId: string, conversionId: string, userData: UserData): R.ResultP<void, HttpError> {
  return del(mkConversionUrl(apiId, harvestId, conversionId), userData);
}

// FDP Publication {{{1

function mkPublicationsUrl(apiId: string, harvestId: string, conversionId: string): string {
  return `${mkConversionUrl(apiId, harvestId, conversionId)}/publications`;
}

function mkPublicationUrl(apiId: string, harvestId: string, conversionId: string, publicationId: string): string {
  return `${mkPublicationsUrl(apiId, harvestId, conversionId)}/${publicationId}`;
}

export function startPublication(apiId: string, catalogId: string, harvestId: string, conversionId: string, userData: UserData): R.ResultP<string, HttpError> {
  const url = `${mkPublicationsUrl(apiId, harvestId, conversionId)}/start`;
  return post(url, userData, ResponseType.TEXT, { catalogId });
}

export function getPublication(apiId: string, harvestId: string, conversionId: string, userData: UserData): R.ResultP<Publication[], HttpError> {
  return get(`${mkPublicationsUrl(apiId, harvestId, conversionId)}?latest=true`, userData);
}

export function getPublicationInfo(apiId: string, harvestId: string, conversionId: string, publicationId: string, userData: UserData): R.ResultP<JobInfo, HttpError> {
  //console.log("getPublicationStatus");
  //console.log({apiId, harvestId, conversionId, publicationId});
  return get(`${mkPublicationUrl(apiId, harvestId, conversionId, publicationId)}/status`, userData);
}

export function getPublishedMetadata(apiId: string, harvestId: string, conversionId: string, publicationId: string, userData: UserData): R.ResultP<PublishedMetadata[], HttpError> {
  return get(`${mkPublicationUrl(apiId, harvestId, conversionId, publicationId)}/published-metadata`, userData);
}
