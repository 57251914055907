/* eslint-disable react/display-name */
import _ from "lodash";
import * as R from "result-async";
import * as P from "pipeout";
import React from "react";
import { FormControl, InputLabel, LinearProgress, MenuItem, Select, Stack, SxProps, Tooltip } from "@mui/material";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  orange as ORANGE,
  blue as BLUE,
  grey as GREY,
} from "@mui/material/colors";
import type {
  SemanticPropertyInfo,
} from "@fair-space/core/types/mapping";
import type {
  MappingLineFull,
} from "@app/Models/mapping";
import * as rdfApi from "@app/Api/rdf";
import {
  Domain,
  Endorsement,
  selectMappingsByDomain,
} from "@app/Models/mapping";
import type { Harvest } from "@app/Api/harvests";
import {
  ApiDocumentProp,
  IndicationProps,
  PreviewProp,
  CancelClientProps,
  UserProp,
  CallAsyncProp,
} from "@app/Components/definitions";
import { FoldableTR } from "./FoldableTR";
import { PageHeading } from "@app/Components/UIComponents";
import { MapperView, type LineRenderer } from "./MapperView";
import {matchSwitch} from "@babakness/exhaustive-type-checking";
import {HttpError} from "@app/Api/http";

enum CatalogSchemas {
  SEM_DCAT = "Semantic DCAT",
  DCAT = "DCAT v2",
}

type Props =
  IndicationProps &
  CallAsyncProp &
  PreviewProp &
  UserProp &
  CancelClientProps &
  ApiDocumentProp &
  {
    harvest: Harvest;
  };

export function Mapper(props: Props): JSX.Element {
  const [selectedSchema, setSelectedSchema] = React.useState(CatalogSchemas.SEM_DCAT);
  const [schemaLines, setSchemaLines] = React.useState([] as SemanticPropertyInfo[]);

  //// Routines ////

  function getSemDcatSchema(): void {
    props.onLoad(true);
    P.pipeA
      (R.allOkAsync([
        rdfApi.getSemanticArtefactProperties(props.userData),
        rdfApi.getSemanticArtefactDistributionProperties(props.userData),
      ]))
      .thru(R.okDo<SemanticPropertyInfo[][], HttpError>(([saProperties, sadProperties]) => {
        props.onLoad(false);
        setSchemaLines([...saProperties, ...sadProperties]);
      }))
      .thru(R.errorDo(props.onError))
      .value();
  }

  function getDcatSchema(): void {
    props.onLoad(true);
    P.pipeA
      (R.allOkAsync([
        rdfApi.getDcatCatalogProperties(props.userData)
      ]))
      .thru(R.okDo<SemanticPropertyInfo[][], HttpError>(([caProperties]) => {
        props.onLoad(false);
        setSchemaLines([...caProperties]);
      }))
      .thru(R.errorDo(props.onError))
      .value();
  }

  //// Hooks ////

  // Get semantic schema on its selection
  React.useEffect(() => {
    matchSwitch(selectedSchema, {
      [CatalogSchemas.SEM_DCAT]: getSemDcatSchema,
      [CatalogSchemas.DCAT]: getDcatSchema,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSchema]);

  //// Rendering ////

  function renderSelectSemanticSchema(): JSX.Element {
    return (
      <FormControl variant="filled" sx={{ minWidth: 150 }}>
        <InputLabel id="catalog-schema">Catalog Schema</InputLabel>
        <Select
          labelId="catalog-schema"
          value={selectedSchema}
          onChange={ev => setSelectedSchema(ev.target.value as CatalogSchemas)}
          label="Catalog Schema"
        >
          <MenuItem value={CatalogSchemas.SEM_DCAT}>{CatalogSchemas.SEM_DCAT}</MenuItem>
          <MenuItem value={CatalogSchemas.DCAT}>{CatalogSchemas.DCAT}</MenuItem>
        </Select>
      </FormControl>
    );
  }

  function renderSectionHeading(title: string|JSX.Element, filledMappingsNo: number, totalMappingsNo: number): JSX.Element {
    return (
      <Stack direction="row" spacing={2} alignItems="center">
        <span>{title}</span>
        <Tooltip title={`Filled: ${filledMappingsNo}/${totalMappingsNo}`}>
          <LinearProgress
            sx={{width: 200}}
            variant="determinate"
            value={Math.round(filledMappingsNo / totalMappingsNo * 100)}
          />
        </Tooltip>
      </Stack>
    );
  }

  function renderSemDcatTable(mappings: MappingLineFull[], filledMappings: MappingLineFull[] , renderLine: LineRenderer): JSX.Element {
    const headingCss: SxProps = { backgroundColor: GREY[200] };
    const artefactHeadingCss: SxProps = {
      backgroundColor: ORANGE[200],
      fontWeight: "bold",
    };
    const artefactHeading2Css: SxProps = {
      backgroundColor: ORANGE[200],
      fontStyle: "italic",
    };
    const artefactItemCss: SxProps = { backgroundColor: ORANGE[50] };
    const artefactDistHeadingCss: SxProps = {
      backgroundColor: BLUE[200],
      fontWeight: "bold",
    };
    const artefactDistHeading2Css: SxProps = {
      backgroundColor: BLUE[200],
      fontStyle: "italic",
    };
    const artefactDistItemCss: SxProps = { backgroundColor: BLUE[50] };

    const saMappings = selectMappingsByDomain(mappings, Domain.SEMANTIC_ARTEFACT) as MappingLineFull[];
    const sadMappings = selectMappingsByDomain(mappings, Domain.SEMANTIC_ARTEFACT_DISTRIBUTION) as MappingLineFull[];
    const saMappingsMandatory = saMappings.filter(m => m.schemaItem.endorsement === Endorsement.MANDATORY);
    const saMappingsRecommended = saMappings.filter(m => m.schemaItem.endorsement === Endorsement.RECOMMENDED);
    const saMappingsOptional = saMappings.filter(m => m.schemaItem.endorsement === Endorsement.OPTIONAL);
    const sadMappingsMandatory = sadMappings.filter(m => m.schemaItem.endorsement === Endorsement.MANDATORY);
    const sadMappingsRecommended = sadMappings.filter(m => m.schemaItem.endorsement === Endorsement.RECOMMENDED);
    const sadMappingsOptional = sadMappings.filter(m => m.schemaItem.endorsement === Endorsement.OPTIONAL);
    const filledSaMappings = selectMappingsByDomain(filledMappings, Domain.SEMANTIC_ARTEFACT) as MappingLineFull[];
    const filledSadMappings = selectMappingsByDomain(filledMappings, Domain.SEMANTIC_ARTEFACT_DISTRIBUTION) as MappingLineFull[];
    const filledSaMappingsMandatory = filledSaMappings.filter(m => m.schemaItem.endorsement === Endorsement.MANDATORY);
    const filledSaMappingsRecommended = filledSaMappings.filter(m => m.schemaItem.endorsement === Endorsement.RECOMMENDED);
    const filledSaMappingsOptional = filledSaMappings.filter(m => m.schemaItem.endorsement === Endorsement.OPTIONAL);
    const filledSadMappingsMandatory = filledSadMappings.filter(m => m.schemaItem.endorsement === Endorsement.MANDATORY);
    const filledSadMappingsRecommended = filledSadMappings.filter(m => m.schemaItem.endorsement === Endorsement.RECOMMENDED);
    const filledSadMappingsOptional = filledSadMappings.filter(m => m.schemaItem.endorsement === Endorsement.OPTIONAL);

    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={headingCss}>{renderSectionHeading("Semantic DCAT item", filledMappings.length, mappings.length)}</TableCell>
              <TableCell sx={headingCss}>Metadata item</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <FoldableTR colSpan={2} headingCss={artefactHeadingCss}
              heading={renderSectionHeading(
                "Semantic Artefact",
                filledSaMappings.length,
                saMappings.length
              )}
            >
              <FoldableTR colSpan={2} headingCss={artefactHeading2Css}
              heading={renderSectionHeading(
                "Mandatory Properties",
                filledSaMappingsMandatory.length,
                saMappingsMandatory.length
              )}
              >
                {saMappingsMandatory.map(l => renderLine(l, artefactItemCss))}
              </FoldableTR>
              <FoldableTR colSpan={2} headingCss={artefactHeading2Css}
                heading={renderSectionHeading(
                  "Recommended Properties",
                  filledSaMappingsRecommended.length,
                  saMappingsRecommended.length
                )}
              >
                {saMappingsRecommended.map(l => renderLine(l, artefactItemCss))}
              </FoldableTR>
              <FoldableTR colSpan={2} headingCss={artefactHeading2Css}
                heading={renderSectionHeading(
                  "Optional Properties",
                  filledSaMappingsOptional.length,
                  saMappingsOptional.length
                )}
              >
                {saMappingsOptional.map(l => renderLine(l, artefactItemCss))}
              </FoldableTR>
            </FoldableTR>
            <FoldableTR colSpan={2} headingCss={artefactDistHeadingCss}
              heading={renderSectionHeading(
                "Semantic Artefact Distribution",
                filledSadMappings.length,
                sadMappings.length
              )}
            >
              <FoldableTR
                colSpan={2} headingCss={artefactDistHeading2Css}
                heading={renderSectionHeading(
                  "Mandatory Properties",
                  filledSadMappingsMandatory.length,
                  sadMappingsMandatory.length
                )}
              >
                {sadMappingsMandatory.map(l => renderLine(l, artefactDistItemCss))}
              </FoldableTR>
              <FoldableTR colSpan={2} headingCss={artefactDistHeading2Css}
                heading={renderSectionHeading(
                  "Recommended Properties",
                  filledSadMappingsRecommended.length,
                  sadMappingsRecommended.length
                )}
              >
                {sadMappingsRecommended.map(l => renderLine(l, artefactDistItemCss))}
              </FoldableTR>
              <FoldableTR colSpan={2} headingCss={artefactDistHeading2Css}
                heading={renderSectionHeading(
                  "Optional Properties",
                  filledSadMappingsOptional.length,
                  sadMappingsOptional.length
                )}
              >
                {sadMappingsOptional.map(l => renderLine(l, artefactDistItemCss))}
              </FoldableTR>
            </FoldableTR>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  function renderDcatTable(mappings: MappingLineFull[], filledMappings: MappingLineFull[], renderLine: LineRenderer): JSX.Element {
    const caMappings = selectMappingsByDomain(mappings, Domain.DCAT_CATALOG) as MappingLineFull[];

    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{backgroundColor: GREY[200]}}>
              <TableCell>
                {renderSectionHeading(
                  <span><a href="https://www.w3.org/TR/vocab-dcat-2" target="_blank" rel="noreferrer">DCAT2</a> Catalog property</span>,
                  filledMappings.length,
                  mappings.length)}
              </TableCell>
              <TableCell>
                Value
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {caMappings.map(l => renderLine(l, {}))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return (
    <>
      <PageHeading
        title={
          <Box component="span">
            Mappings for: <Box component="span" sx={{fontWeight: "bolder"}}>{props.apiDocument?.doc.info.title || ""}</Box>
          </Box>
        }
        onCancelled={props.requestCancel}
      />
      {renderSelectSemanticSchema()}
      <MapperView
        {...props}
        schemaLines={schemaLines}
        domains = {matchSwitch(selectedSchema, {
          [CatalogSchemas.SEM_DCAT]: () => [Domain.SEMANTIC_ARTEFACT, Domain.SEMANTIC_ARTEFACT_DISTRIBUTION] as Domain[],
          [CatalogSchemas.DCAT]: () => [Domain.DCAT_CATALOG] as Domain[],
        })}
        renderTable={matchSwitch(selectedSchema, {
          [CatalogSchemas.SEM_DCAT]: () => renderSemDcatTable,
          [CatalogSchemas.DCAT]: () => renderDcatTable,
        })}
      />
    </>
  );
}
