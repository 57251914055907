import _ from "lodash";
import React, { CSSProperties } from "react";
import { JSONPath } from "jsonpath-plus";
import {
  Autocomplete,
  Box,
  Chip,
  IconButton,
  Stack,
  TextField,
  Tooltip
} from "@mui/material";
import {
  MyLocation as MetadataLocatorIcon,
} from "@mui/icons-material";
import { JsonPreview } from "@app/Components/JsonPreview";
import { PreviewProp } from "@app/Components/definitions";

type Props = PreviewProp &
  {
    label: string;
    jsonPathItems: string[];
    metadata?: unknown;
    value: string|null;
    onValueChanged: (item: string|null) => void;
    onSchemaViewRequested: (item: string|null) => void;
    required?: boolean;
    variant?: "standard" | "filled" | "outlined";
    style?: CSSProperties;
  }

export function FieldSelector(props: Props): JSX.Element {
  const [selectedItem, setSelectedItem] = React.useState(props.value);
  const [json, setJson] = React.useState(null as null|unknown);
  const [jsonError, setJsonError] = React.useState("");

  /// Hooks ///
  React.useEffect(() => setSelectedItem(props.value), [props.value]);
  //React.useEffect(() => console.log("FieldSelector: " + props.value), [props.value]);

  React.useEffect(
    () => {
      if (props.metadata && selectedItem) {
        try {
          const res = JSONPath({ json: props.metadata as object, path: selectedItem});
          setJson(res);
          setJsonError("");
        } catch (err) {
          setJson(null);
          setJsonError((err as Error).message);
        }
      }
    },
    [props.metadata, selectedItem]
  );

  /// Routines ///
  function selectedChanged(newValue: string|null): void {
    setSelectedItem(newValue);
    props.onValueChanged(newValue);
  }

  function showPreview(): void {
    if (json) {
      props.onPreview(<JsonPreview json={json} />);
    }
  }

  function renderLocator(): JSX.Element {
    const disabled = !props.metadata;
    return (
      <Box sx={{mr: 1}}>
        <Tooltip title={disabled ? "No metadata available" : "View / select item in the schema"}>
          <div>
            <IconButton
              size="small"
              color="primary"
              disabled={disabled}
              onClick={() => props.onSchemaViewRequested(selectedItem)}
            >
              <MetadataLocatorIcon />
            </IconButton>
          </div>
        </Tooltip>
      </Box>
    );
  }

  function renderMetadataPreview(): JSX.Element {
    return (
      <Box sx={{pl: 1}}>
        {jsonError ?
          <Chip
            color="error"
            label={`Error: ${jsonError}`}
          />
        : _.isArray(json) && (json as Array<unknown>).length > 0 ?
          <Tooltip title="Preview result">
            <div>
              <Chip
                color="primary"
                label={`${(json as Array<unknown>).length} items`}
                disabled={!selectedItem}
                onClick={showPreview}
              />
            </div>
          </Tooltip>
        : <Chip
            color="warning"
            label="No results"
          />
        }
      </Box>
    );
  }

  return (
    <Stack direction="row" alignItems="flex-end">
      {renderLocator()}
      <Autocomplete
        options={props.jsonPathItems}
        freeSolo
        size="small"
        style={props.style}
        renderInput={(params) =>
          <TextField
            {...params}
            label={props.label}
            required={props.required !== undefined ? props.required : false}
            variant={props.variant || "standard"}
          />
        }
        value={selectedItem}
        onInputChange={(_ev, newValue) => selectedChanged(newValue)}
      />
      {props.metadata ?
        renderMetadataPreview()
      : <></>
      }
    </Stack>
  );
}
