/* eslint-disable react/display-name */
import _ from "lodash";
import * as R from "result-async";
import React from "react";
import { matchSwitch } from "@babakness/exhaustive-type-checking";
import { JSONPath } from "jsonpath-plus";
import { saveAs } from "file-saver";
import {
  FormControl,
  Stack,
  Grid,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Radio,
  Button,
  Divider,
  TextField,
  Alert,
  Autocomplete,
} from "@mui/material";
import {
  Done as FinishedIcon,
  FileDownload as ExportIcon,
  Download as RetrieveIcon,
  Cancel as CloseIcon,
} from "@mui/icons-material";

import type { Field } from "@fair-space/core/analyser";
import { mkField, getJsonPaths } from "@fair-space/core/analyser";
import type { ApiDocumentData } from "@app/Api/repos";
import * as reposApi from "@app/Api/repos";
import * as harvestsApi from "@app/Api/harvests";
import {
  ApiDocumentProp,
  CallAsyncProp,
  CancelClientProps,
  IndicationProps,
  PreviewProp,
  UserProp,
} from "@app/Components/definitions";
import {
  FSFormGroup,
  FSInputField,
  FSSuccessChip,
} from "@app/Components/UIComponents";
import { Importer, jsonMime } from "@app/Components/Importer";
import { FieldSelector } from "@app/Components/FieldSelector";
import { SchemaView } from "@app/Components/SchemaView";
import { SecuritySchemeTypeEnum, ContactRole } from "@fair-space/core/types/openapi";
import { JsonPreview } from "@app/Components/JsonPreview";
import {HttpError} from "@app/Api/http";

interface QueryParamsData {
  securityScheme: reposApi.SecuritySchemeTypeEnum | "";
  securitySchemePosition: reposApi.SecuritySchemePositionEnum;
  apiKeyParam: string;
  apiKeyValue: string;
  appended: boolean;
}

function mkQueryParams(data: QueryParamsData): string {
  return data.securityScheme === reposApi.SecuritySchemeTypeEnum.API_KEY &&
    data.securitySchemePosition === reposApi.SecuritySchemePositionEnum.QUERY
    ? `${data.appended ? "&" : "?"}${data.apiKeyParam}=${data.apiKeyValue}`
    : "";
}

type OntViewRequest = {
  requested: boolean;
  selector: string|null;
}

const noOntViewRequest: OntViewRequest = {
  requested: false,
  selector: null,
};


type Props =
  IndicationProps &
  CallAsyncProp &
  PreviewProp &
  UserProp &
  CancelClientProps &
  Partial<ApiDocumentProp> &
  {
    onApiDocumentSaved: (apiId: string) => void;
  }

export function ApiDescriptionForm(props: Props): JSX.Element {
  const [importedJson, setImportedJson] = React.useState(null as null|reposApi.OApiDocument);
  const [formData, setFormData] = React.useState(mkInitialFormData());
  const [originalFormData, setOriginalFormData] = React.useState(null as null|ApiDocumentData);
  const [ontSchema, setOntSchema] = React.useState(null as null | Field);
  const [ontSchemaSelectors, setOntSchemaSelectors] = React.useState(null as string[] | null);
  const [ontFieldViewRequest, setOntFieldViewRequest] = React.useState(noOntViewRequest);
  const [ontListFinalUrl, setOntListFinalUrl] = React.useState("");
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [ontologiesData, setOntologiesData] = React.useState(null as any);
  const [ontFinalUrl, setOntFinalUrl] = React.useState("");
  const [ontRetrieved, setOntRetrieved] = React.useState(false);

  function mkInitialFormData(): ApiDocumentData {
    const oApiDoc = props.apiDocument?.doc;
    return {
      apiTitle: oApiDoc?.info.title || "",
      apiDescription: oApiDoc?.info.description || "",
      apiVersion: oApiDoc?.info.version || "",
      apiTermsUrl: oApiDoc?.info.termsOfService || "",
      contactEmail: oApiDoc?.info.contact?.email || props.userData.user.email,
      contactName: oApiDoc?.info.contact?.name || props.userData.user.firstName + " " + props.userData.user.lastName,
      contactUrl: oApiDoc?.info.contact?.url || "",
      contactRole: oApiDoc?.info.contact?.["x-role"],
      serverUrl: oApiDoc?.servers[0].url || "",
      securityScheme: null, // Initialised below
      securitySchemePosition: reposApi.SecuritySchemePositionEnum.QUERY, // Initialised below
      secCredId: null,
      apiKeyParam: "",
      apiKeyValue: "",
      retrievalType: (oApiDoc ? reposApi.getRetrievalType(oApiDoc) : null) || reposApi.RetrievalTypeEnum.TWO_STEP,
      listPath: (oApiDoc ? reposApi.getListPath(oApiDoc) : null) || "",
      ontIdSelectorJsonPath: oApiDoc? reposApi.getOntIdSelector(oApiDoc) : null,
      ontPath: (oApiDoc ? reposApi.getOntPath(oApiDoc) : null) || "",
      harvestMaxConcurrent: props.apiDocument?.harvestingConstraints.concurrencyConstraints.concurrency?.toString() || reposApi.defaultHarvestingConstraints.concurrencyConstraints.concurrency!.toString(),
      harvestTimeoutMs: props.apiDocument?.harvestingConstraints.concurrencyConstraints.timeoutMs?.toString() || reposApi.defaultHarvestingConstraints.concurrencyConstraints.timeoutMs!.toString(),
      harvestIntervalMs: props.apiDocument?.harvestingConstraints.concurrencyConstraints.intervalMs?.toString() || reposApi.defaultHarvestingConstraints.concurrencyConstraints.intervalMs!.toString(),
      harvestIntervalCap: props.apiDocument?.harvestingConstraints.concurrencyConstraints.intervalCap?.toString() || reposApi.defaultHarvestingConstraints.concurrencyConstraints.intervalCap!.toString(),
      harvestLimit: props.apiDocument?.harvestingConstraints.logicalConstraints.limit?.toString() || "",
      lastChecked: props.apiDocument?.lastChecked,
    };
  }

  function updateFormData<K extends keyof ApiDocumentData>(key: K, value: ApiDocumentData[K]): void {
    setFormData(oldFormData => ({...oldFormData, [key]: value}));
  }

  // used for FSInputField update hooks
  function updateFormDataPartial<K extends keyof ApiDocumentData>(key: K): ((value: ApiDocumentData[K]) => void) {
    return value => { updateFormData(key, value); };
  }

  //React.useEffect(() => console.log(ontIdSelectorJsonPath), [ontIdSelectorJsonPath]);
  //React.useEffect(() => console.log(ontologiesData), [ontologiesData]);
  //React.useEffect(() => console.log(ontIdSelector), [ontIdSelector]);

  //// Hooks ////

  // Fill-in document from importedJson
  React.useEffect(
    () => {
      if (importedJson) {
        fillDocument(importedJson);
        setImportedJson(null);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [importedJson]
  );

  // Initialize security fields on start
  React.useEffect(() => {
    const apiDoc = props.apiDocument;
    if (apiDoc) {
      props.callAsync(
        () => reposApi.getSecCred(props.userData, apiDoc.id),
        res => {
          const secCredId1 = res?.secCredId || null;
          const apiKeyValue1 = res?.value || "";
          updateFormData("secCredId", secCredId1);
          updateFormData("apiKeyValue", apiKeyValue1);
          const securityEither = reposApi.detectSecurity(apiDoc.doc);
          R.either(
            securityEither,
            security => {
              if (security) {
                const securityScheme1 = security.type;
                const securitySchemePosition1 = security.in;
                const apiKeyParam1 = security.name;
                updateFormData("securityScheme", securityScheme1);
                updateFormData("securitySchemePosition", securitySchemePosition1);
                updateFormData("apiKeyParam", apiKeyParam1);
                setOriginalFormData({
                  ...mkDocumentData(),
                  secCredId: secCredId1,
                  securityScheme: securityScheme1,
                  securitySchemePosition: securitySchemePosition1,
                  apiKeyParam: apiKeyParam1,
                  apiKeyValue: apiKeyValue1,
                });
              } else {
                setOriginalFormData(mkDocumentData());
              }
            },
            props.onError
          );
        }
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set the canCancel function
  React.useEffect(
    () => {
      props.setCanCancelFn(() => () => _.isEqual(originalFormData, mkDocumentData()));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [originalFormData, formData]
  );

  // Make ontology schema selectors as soon as ontSchema is available
  React.useEffect(
    () => {
      if (ontSchema) {
        setOntSchemaSelectors(getJsonPaths(ontSchema));
      }
    },
    [ontSchema]
  );

  // Compose ontology get list URL
  React.useEffect(
    () => {
      if (
        formData.serverUrl &&
        formData.listPath &&
        (formData.securityScheme === SecuritySchemeTypeEnum.API_KEY ? formData.apiKeyParam && formData.apiKeyValue : true)
      ) {
        setOntListFinalUrl(
          formData.serverUrl +
            formData.listPath +
            mkQueryParams({
              securityScheme: formData.securityScheme || "",
              securitySchemePosition: formData.securitySchemePosition,
              apiKeyParam: formData.apiKeyParam,
              apiKeyValue: formData.apiKeyValue,
              appended: formData.listPath.includes("?"),
          })
        );
      } else {
        setOntListFinalUrl("");
      }
    },
    [formData.serverUrl, formData.securityScheme, formData.securitySchemePosition, formData.apiKeyParam, formData.apiKeyValue, formData.listPath]
  );

  // Compose get ontology URL
  React.useEffect(
    () => {
      if (
        formData.serverUrl &&
        formData.ontPath &&
        ontologiesData &&
        formData.ontIdSelectorJsonPath &&
        (formData.securityScheme === SecuritySchemeTypeEnum.API_KEY ? formData.apiKeyParam && formData.apiKeyValue : true)
      ) {
        try {
          const firstOntId = JSONPath({ json: ontologiesData, path: formData.ontIdSelectorJsonPath })[0];
          setOntFinalUrl(
            formData.serverUrl +
              formData.ontPath.replace("{}", firstOntId) +
              mkQueryParams({
                securityScheme: formData.securityScheme || "",
                securitySchemePosition: formData.securitySchemePosition,
                apiKeyParam: formData.apiKeyParam,
                apiKeyValue: formData.apiKeyValue,
                appended: formData.ontPath.includes("?")
            })
          );
        } catch (err) {
          console.error(err);
          //props.onError("JSONPath resolution failed: " + (err as Error).message);
        }
      } else {
        setOntFinalUrl("");
        setOntRetrieved(false);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [
      formData.serverUrl,
      formData.securityScheme,
      formData.securitySchemePosition,
      formData.apiKeyParam,
      formData.apiKeyValue,
      formData.ontIdSelectorJsonPath,
      formData.ontPath,
      ontologiesData,
    ]
  );

  // Update preview modal
  React.useEffect(
    () => {
      const schemaView =
        ontSchema && ontSchemaSelectors && ontFieldViewRequest.requested ?
          <SchemaView
            title="Select the identifier of an item"
            metadataSchema={ontSchema}
            selectedFieldJsonPath={ontFieldViewRequest.selector}
            onSelectedFieldJsonPathChange={newJsonPath => {
              const jsonPathItem = ontSchemaSelectors.find(jsonPath => jsonPath === newJsonPath);
              if (jsonPathItem) {
                updateFormData("ontIdSelectorJsonPath", jsonPathItem);
              } else {
                console.error(`selector with id=${newJsonPath} not found, this is weird`);
                updateFormData("ontIdSelectorJsonPath", null);
              }
              setOntFieldViewRequest(noOntViewRequest);
            }}
          />
        : null;

        props.onPreview(schemaView, () => setOntFieldViewRequest(noOntViewRequest));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ontFieldViewRequest, ontSchema, ontSchemaSelectors]
  );

  //// Routines ////

  function fillDocument(doc: reposApi.OApiDocument|null): void {
    // This routine is used also for filling from import, so it assumes any field may be missing
    updateFormData("apiTitle", (doc?.info?.title || "") + " (imported)");
    updateFormData("apiDescription", doc?.info?.description || "");
    updateFormData("apiVersion", doc?.info?.version || "");
    updateFormData("apiTermsUrl", doc?.info?.termsOfService || "");
    updateFormData("contactEmail", doc?.info?.contact?.email || props.userData.user.email);
    updateFormData("contactName", doc?.info?.contact?.name || props.userData.user.firstName + " " + props.userData.user.lastName);
    updateFormData("contactUrl", doc?.info?.contact?.url || "");
    updateFormData("contactRole", doc?.info?.contact?.["x-role"]);
    updateFormData("serverUrl", doc?.servers?.[0]?.url || "");
    updateFormData("retrievalType", (doc ? reposApi.getRetrievalType(doc) : null) || reposApi.RetrievalTypeEnum.TWO_STEP);
    updateFormData("listPath", (doc ? reposApi.getListPath(doc) : null) || "");
    updateFormData("ontIdSelectorJsonPath", (doc ? reposApi.getOntIdSelector(doc) : "") || "");
    updateFormData("ontPath", (doc ? reposApi.getOntPath(doc) : null) || "");
    if (doc) {
      const securityEither = reposApi.detectSecurity(doc);
      R.either(
        securityEither,
        security => {
          if (security) {
            const securityScheme1 = security.type;
            const securitySchemePosition1 = security.in;
            const apiKeyParam1 = security.name;
            updateFormData("securityScheme", securityScheme1);
            updateFormData("securitySchemePosition", securitySchemePosition1);
            updateFormData("apiKeyParam", apiKeyParam1);
          }
        },
        props.onError
      );
    }
  }

  function retrieveList(): void {
    props.callAsync(
      () => saveDocument(),
      apiId => props.callAsync(
        () => reposApi.getAPIDocument(apiId, props.userData),
        apiDoc => props.callAsync(
          () => reposApi.getSecCred(props.userData, apiDoc.id), // Must be retrieved here, as secCredId updates itself later
          secCredRes => props.callAsync(
            () => harvestsApi.getAllIds(apiDoc, secCredRes?.secCredId ? secCredRes?.secCredId : undefined, props.userData),
            probeResponse => {
              if (probeResponse.response.status === 200) {
                const data = probeResponse.response.data;
                setOntologiesData(data);
                const schema = mkField(data);
                setOntSchema(schema);
                props.onPreview(<JsonPreview json={data} />);
              } else {
                props.onError(`${probeResponse.response.statusText} (${probeResponse.response.status})`);
              }
            }
          )
        )
      )
    );
  }

  function retrieveOntology(): void {
    if (ontologiesData && formData.ontIdSelectorJsonPath) {
      props.callAsync(
        () => saveDocument(),
        apiId => props.callAsync(
          () => reposApi.getAPIDocument(apiId, props.userData),
          apiDoc => {
            const firstOntId = JSONPath({ json: ontologiesData, path: formData.ontIdSelectorJsonPath || "" })[0];
            props.callAsync(
              () => harvestsApi.getOneMetadata(apiDoc, formData.secCredId ? formData.secCredId : undefined, firstOntId, props.userData),
              probeResponse => {
                if (probeResponse.response.status === 200) {
                  setOntRetrieved(true);
                  props.onPreview(<JsonPreview json={probeResponse.response.data} />);
                } else {
                  props.onError(`${probeResponse.response.statusText} (${probeResponse.response.status})`);
                }
              }
            );
          }
        )
      );
    }
  }

  function mkDocumentData(): ApiDocumentData {
    return {
      ...formData,
      contactRole: formData.contactRole ? formData.contactRole : undefined,
      ontIdSelectorJsonPath: formData.ontIdSelectorJsonPath ? formData.ontIdSelectorJsonPath : "",
      lastChecked: ontRetrieved ? new Date() : props.apiDocument?.lastChecked,
    };
  }

  function exportDocument(): void {
    function doExport(apiId: string): void {
      props.callAsync(
        () => reposApi.getAPIDocument(apiId, props.userData),
        apiDoc => {
          const blob = new Blob([JSON.stringify(apiDoc.doc, null, 2)], { type: jsonMime });
          const fname = (apiDoc.doc.info.title || "no-title") + ".SmartAPI.json";
          saveAs(blob, fname);
        }
      );
    }

    props.callAsync(
      () => saveDocument(),
      doExport
    );
  }

  async function saveDocument(): R.ResultP<string, HttpError> {
    const data = mkDocumentData();

    function finalize(apiId: string): R.Result<string, HttpError> {
      props.onApiDocumentSaved(apiId);
      setOriginalFormData(data);
      return R.ok(apiId);
    }

    if (props.apiDocument) {
      const apiId = props.apiDocument.id;
      return R.either(
        await reposApi.putAPIDocAndCred(apiId, props.userData, data),
        () => finalize(apiId),
        R.error
      );
    } else {
      return R.either(
        await reposApi.postAPIDocAndCred(props.userData, data),
        finalize,
        R.error,
      );
    }
  }

  //// Rendering /////

  function renderGeneralInfo(): JSX.Element {
    return (
      <FSFormGroup heading="General information">
        <Grid item sm={6}>
          <FSInputField label="Title" required state={[formData.apiTitle, updateFormDataPartial("apiTitle")]} />
        </Grid>
        <Grid item sm={12}>
          <FSInputField multiline label="Description" state={[formData.apiDescription, updateFormDataPartial("apiDescription")]} />
        </Grid>
        <Grid item sm={6}>
          <FSInputField label="Version" state={[formData.apiVersion, updateFormDataPartial("apiVersion")]} />
        </Grid>
        <Grid item sm={6}>
          <FSInputField
            type="url"
            label="Terms of service URL"
            state={[formData.apiTermsUrl, updateFormDataPartial("apiTermsUrl")]}
            required
          />
        </Grid>
      </FSFormGroup>
    );
  }

  function renderContactInfo(): JSX.Element {
    return (
      <FSFormGroup heading="Contact information">
        <Grid item sm={6}>
          <FSInputField label="E-mail" required state={[formData.contactEmail, updateFormDataPartial("contactEmail")]} />
        </Grid>
        <Grid item sm={6}>
          <FSInputField label="Name" required state={[formData.contactName, updateFormDataPartial("contactName")]} />
        </Grid>
        <Grid item sm={6}>
          <FSInputField label="URL" state={[formData.contactUrl, updateFormDataPartial("contactUrl")]} />
        </Grid>
        <Grid item sm={6}>
          <Autocomplete
            options={["", ...Object.values(ContactRole)]}
            size="small"
            renderInput={params => (
              <TextField {...params} label="Role" required={true} variant="standard" />
            )}
            value={formData.contactRole}
            onChange={(_ev, newValue) => {
              const entry = Object.entries(ContactRole).find(([_k, v]) => v === newValue);
              updateFormData("contactRole", entry ? ContactRole[entry[0] as keyof typeof ContactRole] : undefined);
            }}
          />
        </Grid>
      </FSFormGroup>
    );
  }

  function renderSecurity(): JSX.Element {
    function renderAPIkeyInput(): JSX.Element {
      return (
        <>
          <Grid item sm={3}>
            <FSInputField
              label="API Key Parameter"
              required
              state={[formData.apiKeyParam, updateFormDataPartial("apiKeyParam")]}
            />
          </Grid>
          <Grid item sm={3}>
            <FSInputField label="API Key Value" required state={[formData.apiKeyValue, updateFormDataPartial("apiKeyValue")]} />
          </Grid>
          <Grid item sm={6}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Placement:</FormLabel>
              <RadioGroup
                row
                value={formData.securitySchemePosition}
                onChange={ev =>
                  updateFormData("securitySchemePosition", ev.target.value as reposApi.SecuritySchemePositionEnum)
                }
              >
                {Object.keys(reposApi.SecuritySchemePositionEnum).map((key, i) => (
                  <FormControlLabel
                    key={i}
                    control={<Radio />}
                    label={
                      reposApi.SecuritySchemePositionEnum[
                        key as keyof typeof reposApi.SecuritySchemePositionEnum
                      ]
                    }
                    value={
                      reposApi.SecuritySchemePositionEnum[
                        key as keyof typeof reposApi.SecuritySchemePositionEnum
                      ]
                    }
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        </>
      );
    }

    function renderSecurityInput(): JSX.Element {
      const noy = (
        <Grid item>
          <Alert severity="info">Not implemented yet</Alert>
        </Grid>
      );
      return formData.securityScheme ? (
        matchSwitch(formData.securityScheme, {
          [reposApi.SecuritySchemeTypeEnum.API_KEY]: () => renderAPIkeyInput(),
          [reposApi.SecuritySchemeTypeEnum.HTTP]: () => noy,
          [reposApi.SecuritySchemeTypeEnum.OAUTH_2]: () => noy,
          [reposApi.SecuritySchemeTypeEnum.OIDC]: () => noy,
        })
      ) : (
        <></>
      );
    }

    return (
      <>
        <Grid item sm={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Security</FormLabel>
            <RadioGroup
              row
              value={formData.securityScheme}
              onChange={ev =>
                updateFormData(
                  "securityScheme",
                  (ev.target as HTMLInputElement).value as reposApi.SecuritySchemeTypeEnum
                )
              }
            >
              <FormControlLabel key={0} control={<Radio />} label="none" value="" />
              {Object.keys(reposApi.SecuritySchemeTypeEnum).map((key, i) => (
                <FormControlLabel
                  key={i + 1}
                  control={<Radio />}
                  label={reposApi.SecuritySchemeTypeEnum[key as keyof typeof reposApi.SecuritySchemeTypeEnum]}
                  value={reposApi.SecuritySchemeTypeEnum[key as keyof typeof reposApi.SecuritySchemeTypeEnum]}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
        {renderSecurityInput()}
      </>
    );
  }

  function renderServerInfo(): JSX.Element {
    return (
      <FSFormGroup heading="Server information">
        <Grid item sm={12}>
          <FSInputField
            label="URL of the server"
            type="url"
            required
            state={[formData.serverUrl, updateFormDataPartial("serverUrl")]}
          />
        </Grid>
        {renderSecurity()}
      </FSFormGroup>
    );
  }

  function renderMetadataRetrieval(): JSX.Element {
    function renderPerRepository(): JSX.Element {
      return (
        <Grid item xs={12}>
          <Alert severity="info">Not implemented yet</Alert>
        </Grid>
      );
    }

    function renderPerOntology(): JSX.Element {
      return (
        <>
          <Grid item sm={4}>
            <FSInputField
              variant="outlined"
              label="Path to retrieve list of all ontologies"
              type="url"
              required
              placeholder="/path"
              state={[formData.listPath, updateFormDataPartial("listPath")]}
            />
          </Grid>
          <Grid item sm={8}>
            <TextField
              fullWidth
              label="Resulting URL"
              type="url"
              variant="outlined"
              disabled
              value={ontListFinalUrl}
            />
          </Grid>
          <Grid item sm={12}>
            <Stack direction="row" alignItems="center">
              <Button
                variant="contained"
                startIcon={<RetrieveIcon />}
                disabled={!ontListFinalUrl}
                onClick={retrieveList}
              >
                Test connection & generate list schema
              </Button>
              {ontologiesData ? <FSSuccessChip sx={{ ml: 2 }} label="Success" /> : <></>}
            </Stack>
          </Grid>
          <Grid item sm={12}>
            <Divider />
          </Grid>
          <Grid item sm={12}>
            <FieldSelector
              variant="outlined"
              label="JSONPath to identifier of a dataset used to retrieve dataset metadata"
              value={formData.ontIdSelectorJsonPath}
              metadata={ontologiesData}
              jsonPathItems={ontSchemaSelectors || []}
              onValueChanged={updateFormDataPartial("ontIdSelectorJsonPath")}
              onSchemaViewRequested={s => setOntFieldViewRequest({ requested: true, selector: s })}
              onPreview={props.onPreview}
              required={true}
              style={{width: "100%"}}
            />
          </Grid>
          <Grid item sm={12}>
            <FSInputField
              variant="outlined"
              label="path to retrieve single dataset metadata"
              type="url"
              required
              placeholder="/pathBeforeIdentifier/{}/pathAfterIdentifier"
              state={[formData.ontPath, updateFormDataPartial("ontPath")]}
              predicates={[
                value => ({
                  isValid: (value.match(/{}/g) || []).length === 1,
                  message:
                    "The path must contain exactly one '{}' denoting the dataset identifier",
                }),
              ]}
            />
          </Grid>
          <Grid item sm={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="Resulting URL Example (the first dataset metadata)"
              type="url"
              disabled
              value={ontFinalUrl}
            />
          </Grid>
          <Grid item sm={12}>
            <Stack direction="row" alignItems="center">
              <Button
                variant="contained"
                startIcon={<RetrieveIcon />}
                disabled={!ontFinalUrl}
                onClick={retrieveOntology}
              >
                Test retrieving the first dataset metadata
              </Button>
              {ontRetrieved ? <FSSuccessChip sx={{ ml: 2 }} label="Success" /> : <></>}
            </Stack>
          </Grid>
        </>
      );
    }

    return (
      <FSFormGroup heading="Metadata retrieval" spacing={3}>
        <Grid item sm={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Method</FormLabel>
            <RadioGroup
              row
              value={formData.retrievalType}
              onChange={ev =>
                updateFormData("retrievalType", (ev.target as HTMLInputElement).value as reposApi.RetrievalTypeEnum)
              }
            >
              <FormControlLabel
                value={reposApi.RetrievalTypeEnum.ONE_STEP}
                control={<Radio />}
                label="1-step process"
              />
              <FormControlLabel
                value={reposApi.RetrievalTypeEnum.TWO_STEP}
                control={<Radio />}
                label="2-step process"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        {matchSwitch(formData.retrievalType, {
          [reposApi.RetrievalTypeEnum.ONE_STEP]: () => renderPerRepository(),
          [reposApi.RetrievalTypeEnum.TWO_STEP]: () => renderPerOntology(),
        })}
      </FSFormGroup>
    );
  }

  function renderActions(): JSX.Element {
    return (
      <Stack direction="row" justifyContent="center" spacing={6}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={<FinishedIcon />}
          onClick={
            () => props.callAsync(
              () => saveDocument(),
              () => void(0)
            )
          }
        >
          Save description
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          startIcon={<ExportIcon />}
          onClick={exportDocument}
        >
          Export SmartAPI
        </Button>
        <Button
          variant="contained"
          color="warning"
          size="large"
          startIcon={<CloseIcon />}
          onClick={props.requestCancel}
        >
          Close
        </Button>
      </Stack>
    );
  }

  function renderHarvestingConstraints(): JSX.Element {
    return (
      <FSFormGroup heading="Harvesting Constraints">
        <Grid item sm={4}>
          <FSInputField
            type="number"
            label="Maximum number of concurrent threads"
            state={[formData.harvestMaxConcurrent, updateFormDataPartial("harvestMaxConcurrent")]}
          />
        </Grid>
        <Grid item sm={4}>
          <FSInputField
            type="number"
            label="Maximum duration of a thread [ms]"
            state={[formData.harvestTimeoutMs, updateFormDataPartial("harvestTimeoutMs")]}
          />
        </Grid>
        <Grid item sm={4}>
          <FSInputField
            type="number"
            label="Interval between requests [ms]"
            state={[formData.harvestIntervalMs, updateFormDataPartial("harvestIntervalMs")]}
          />
        </Grid>
        <Grid item sm={4}>
          <FSInputField
            type="number"
            label="The number of items that can be harvested in one interval time span"
            state={[formData.harvestIntervalCap, updateFormDataPartial("harvestIntervalCap")]}
          />
        </Grid>
        <Grid item sm={4}>
          <FSInputField
            type="number"
            label="Maximum number of items to harvest (blank = all)"
            state={[formData.harvestLimit, updateFormDataPartial("harvestLimit")]}
          />
        </Grid>
      </FSFormGroup>
    );
  }

  return (
    <Stack direction="column" spacing={2} sx={{ height: "100%" }}>
      <Importer
        {...props}
        title="Import SmartAPI"
        onImported={setImportedJson}
      />
      {renderGeneralInfo()}
      {renderContactInfo()}
      {renderServerInfo()}
      {renderMetadataRetrieval()}
      {renderHarvestingConstraints()}
      {renderActions()}
    </Stack>
  );
}
