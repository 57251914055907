import React from "react";
import { matchSwitch } from "@babakness/exhaustive-type-checking";
import {
  Box,
  Button,
  Grid,
  Modal,
  Paper,
  Stack,
  SxProps,
  Tooltip,
} from "@mui/material";
import type { JobInfoRecord} from "@app/Models/harvest";
import { JobStatus } from "@app/Api/harvests";
import { JobStatusChip } from "./JobStatusChip";
import { JobInfoPane } from "./JobInfoPane";

const modalViewCss: SxProps = {
  position: "absolute",
  top: 50,
  maxHeight: "calc(100vh - 100px)",
  left: "calc(100vw / 7)",
  width: "calc(100vw / 7 * 5)",
  p: 2,
  overflow: "auto",
};

type Props = {
  title: string;
  jobInfoRecord: JobInfoRecord;
  disableAction?: boolean;
  disableMessage?: string;
  startAction: () => void;
  restartAction: () => void;
  extraButtons?: JSX.Element;
}

export function JobControlLine(props: Props): JSX.Element {
  const [showInfo, setShowInfo] = React.useState(false);
  const [infoJsx, setInfoJsx] = React.useState(null as null|JSX.Element);

  function updateInfo(): void {
    if (showInfo && props.jobInfoRecord) {
      setInfoJsx(
        <JobInfoPane
          {...props}
          jobInfoRecord={props.jobInfoRecord}
          restartAction={() => {
            props.restartAction();
          }}
        />
      );
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(updateInfo, [showInfo, props.jobInfoRecord]);

  function renderActionButton(): JSX.Element {
    const jobInfo = props.jobInfoRecord.jobInfo;
    return (
      jobInfo ?
        matchSwitch(jobInfo.status, {
          [JobStatus.RUNNING]: () => <Button>Pause</Button>,
          [JobStatus.SCHEDULED]: () => <Button>Cancel</Button>,
          [JobStatus.PAUSED]: () => <Button>Resume</Button>,
          [JobStatus.FINISHED]: () => <Button onClick={props.restartAction}>Restart</Button>,
          [JobStatus.FAILED]: () => <Button onClick={() => setShowInfo(true)}>Errors</Button>,
          [JobStatus.UNKNOWN]: () => <Button onClick={props.restartAction}>Restart</Button>,
        })
      :
        <Tooltip title={props.disableAction ? props.disableMessage || "" : ""}>
          <div>
            <Button
              disabled={props.disableAction}
              onClick={props.startAction}
            >
              Start
            </Button>
          </div>
        </Tooltip>
    );
  }

  function renderInfoPane(): JSX.Element {
    return (
      <Box sx={modalViewCss}>
        <Paper elevation={5} sx={{ m: 1, p: 1 }}>
          {infoJsx || <></>}
        </Paper>
      </Box>
    );
  }


  return (
    <>
      <Grid item xs={6}>
        {props.title}
      </Grid>
      <Grid item xs={3}>
        <Stack direction="row" justifyContent="center">
          <JobStatusChip
            jobInfoRecord={props.jobInfoRecord}
            onClick={() => setShowInfo(true)}
          />
        </Stack>
      </Grid>
      <Grid item xs={3}>
        <Stack direction="row" justifyContent="flex-end">
          {renderActionButton()}
        </Stack>
      </Grid>
      <Modal open={showInfo} onClose={() => setShowInfo(false)}>
        {renderInfoPane()}
      </Modal>
    </>
  );
}


