export const LOGIN_PATH = "auth/keycloak/login";
export const SEC_CRED_PATH = "security-credentials";

export interface FairSpaceWindow extends Window {
  config?: {
    apiUrl?: string;
    fdpUrl?: string;
  };
}

declare let window: FairSpaceWindow;

type Config = {
  SERVER_URL: string;
  FDP_URL: string;
  LOGIN_PATH: string;
  SEC_CRED_PATH: string;
};

export function getConfig(): Config {
  if(!window.config) {
    console.warn("No runtime configuration detected, the application will not work properly.");
    throw new Error("Missing configuration.");
  }

  const { apiUrl, fdpUrl } = window.config;

  if(!apiUrl) {
    console.warn("The apiUrl was not defined, the application will work not properly.");
    throw new Error("Invalid configuration.");
  }

  if(!fdpUrl) {
    console.warn("The fdpUrl was not defined, the application will work not properly.");
    throw new Error("Invalid configuration.");
  }

  return {
    SERVER_URL: apiUrl,
    FDP_URL: fdpUrl,
    LOGIN_PATH,
    SEC_CRED_PATH,
  };
}

