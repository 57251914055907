import React from "react";
import { Button, Chip, ChipProps, Dialog, DialogActions, DialogTitle, IconButton, Stack, SxProps } from "@mui/material";
import {
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import { Cancel as CancelIcon, Done as SuccessIcon } from "@mui/icons-material";

interface FSContainerProps {
  fullWidth?: boolean;
  children: JSX.Element | JSX.Element[];
}

export function FSContainer(props: FSContainerProps): JSX.Element {
  return (
    <Container
      maxWidth={props.fullWidth ? false : "lg"}
      sx={{ height: "calc(100% - 76px)", mt: 2 }}
    >
      <Box sx={{ height: "100%", overflow: "auto", px: 1, py: 2 }}>
        {props.children}
      </Box>
    </Container>
  );
}

interface FSFormGroupProps {
  heading?: string;
  spacing?: number;
  elevation?: number;
  sx?: SxProps;
  children: JSX.Element | JSX.Element[];
}

export function FSFormGroup(props: FSFormGroupProps): JSX.Element {
  return (
    <Paper sx={{ ...props.sx, p: 2 }} elevation={props.elevation || 2}>
      {props.heading ? (
        <Typography variant="h2">{props.heading}</Typography>
      ) : (
        <></>
      )}
      <Grid container spacing={props.spacing || 2} alignItems="flex-end">
        {props.children}
      </Grid>
    </Paper>
  );
}

interface FSInputFieldProps {
  variant?: "standard" | "filled" | "outlined";
  type?: React.InputHTMLAttributes<unknown>["type"];
  label: string;
  required?: boolean;
  multiline?: boolean;
  state: [string, (value: string) => void];
  onValidityChange?: (valid: boolean) => void;
  predicates?: Array<(value: string) => { isValid: boolean; message?: string }>;
  placeholder?: string;
}

export function FSInputField(props: FSInputFieldProps): JSX.Element {
  const [value, updateFormValue] = props.state;
  const [hasError, setHasError] = React.useState(false);
  const [helperText, setHelperText] = React.useState("");

  type Validation = { isValid: boolean; message: string };
  // Errors checking
  React.useEffect(() => {
    const requiredValidation: Validation =
      props.required && value.length === 0
        ? { isValid: false, message: "" }
        : { isValid: true, message: "" };
    const predicatesValidation: Validation[] = (props.predicates || []).map(
      p => {
        const res = p(value);
        return { isValid: res.isValid, message: res.message || "" };
      }
    );
    const validation: Validation[] = [
      requiredValidation,
      ...predicatesValidation,
    ];
    const firstError = validation.find(({ isValid }) => isValid === false);
    if (firstError) {
      setHasError(true);
      setHelperText(firstError.message);
    } else {
      setHasError(false);
      setHelperText("");
    }
    if (props.onValidityChange) {
      props.onValidityChange(!firstError);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.required,
    props.predicates,
    value,
    props.onValidityChange,
  ]);

  // Inform parent about error change
  React.useEffect(() => {
    if (props.onValidityChange) {
      props.onValidityChange(hasError);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onValidityChange, hasError]);

  return (
    <TextField
      fullWidth
      variant={props.variant || "standard"}
      type={props.type || "text"}
      label={props.label}
      multiline={props.multiline || false}
      required={props.required || false}
      error={hasError}
      helperText={helperText}
      value={value}
      onChange={ev => {
        const val = ev.target.value;
        updateFormValue(val);
      }}
      placeholder={props.placeholder}
    />
  );
}

interface FSSuccessChipProps extends ChipProps {
  label?: string | JSX.Element;
}

export function FSSuccessChip(props: FSSuccessChipProps): JSX.Element {
  return (
    <Chip
      {...props}
      color="success"
      label={
        <Stack direction="row" alignItems="center">
          {props.label ? (
            <Box component="span" sx={{ mr: 1 }}>
              {props.label}
            </Box>
          ) : (
            <></>
          )}
          <SuccessIcon fontSize="inherit" />
        </Stack>
      }
    />
  );
}

export function PageHeading({title, onCancelled}: {title: JSX.Element, onCancelled: () => void}): JSX.Element {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography variant="h1">{title}</Typography>
      <IconButton color="warning" size="large" onClick={onCancelled}>
        <CancelIcon fontSize="inherit" />
      </IconButton>
    </Stack>
  );
}

export type ConfirmationDialogProps = {
  question: string;
  open: boolean;
  onYes: () => void;
  onNo: () => void;
}

export function ConfirmationDialog(props: ConfirmationDialogProps): JSX.Element {
  return (
    <Dialog
      open={props.open}
      onClose={props.onNo}
    >
      <DialogTitle sx={{color: "warning.main"}}>
        {props.question}
      </DialogTitle>
      <DialogActions>
        <Button color="error" onClick={props.onYes}>Yes</Button>
        <Button color="primary" onClick={props.onNo} autoFocus>No</Button>
      </DialogActions>
    </Dialog>
  );
}
