import _ from "lodash";
import {
  Endorsement,
  Domain,
} from "@fair-space/core/types";
import type {
  MappingLine,
  SemanticProperty,
  SemanticPropertyInfo,
  Mapping,
  PostMappingBody,
} from "@fair-space/core/types";

export type { Mapping, MappingLine, SemanticProperty, SemanticPropertyInfo, PostMappingBody };
export { Endorsement, Domain };

export type SetFn = React.Dispatch<React.SetStateAction<MappingLineFull[]>>;

// Wokring mapping line that contains schemaItem in the form of full SemanticPropertyInfo
// instead of SemanticProperty
export type MappingLineFull = {
  schemaItem: SemanticPropertyInfo;
  metadataItem: string;
}

export function getMetadataItem(mappingLines: MappingLine[], schemaItem: SemanticPropertyInfo): string|undefined {
  // used also in importing, so make safe
  try {
    const res = mappingLines.find(l => l.schemaItem.uri === schemaItem.uri && l.schemaItem.domain === schemaItem.domain)?.metadataItem;
    return res;
  } catch(_err) { return undefined; }
}

export function selectMappingsByDomain(lines: MappingLine[]|MappingLineFull[], domain: Domain): MappingLine[]|MappingLineFull[] {
  // used also in importing, make it fail-safe
  return (
    lines && _.isArray(lines) ?
      _.sortBy(
        lines
        .filter(l => l?.schemaItem?.domain === domain)
        .map(l => ({
          schemaItem: l.schemaItem,
          metadataItem: l.metadataItem
        })),
        [line => line.schemaItem.name]
      )
    : []
  );
}


