import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Modal,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  grey as GREY,
} from "@mui/material/colors";
import type { SxProps } from "@mui/system";
import {
  MoreVert as MoreVertIcon,
  Share as ShareIcon,
  Delete as DeleteIcon,
  NetworkWifi as CheckedIcon,
  SignalWifiBad as NotCheckedIcon,
  Preview as ViewIcon,
} from "@mui/icons-material";
import smartApiLogoOK from "./logoSmartApi-valid.svg";
import smartApiLogoErrors from "./logoSmartApi-errors.svg";
import type { CallAsyncProp, IndicationProps, PreviewProp, UserProp } from "@app/Components/definitions";
import type { ApiDocument } from "@app/Api/repos";
import { noJobInfo } from "@app/Models/harvest";
import type { Harvest, Conversion, Publication } from "@app/Api/harvests";
import { JobStatus } from "@app/Api/harvests";
import { JobControlLine } from "./JobControlLine";
import { getConfig} from "@app/config";
import * as reposApi from "@app/Api/repos";
import * as harvestsApi from "@app/Api/harvests";
import * as mappingsApi from "@app/Api/mappings";

const config = getConfig();

const modalViewCss: SxProps = {
  position: "absolute",
  top: 50,
  maxHeight: "calc(100vh - 100px)",
  left: "calc(100vw / 7)",
  width: "calc(100vw / 7 * 5)",
  p: 2,
  overflow: "auto",
};

const cardWidth = 380;
const cardHeight = 325;
const iconSize = 32;

type Props =
  IndicationProps &
  CallAsyncProp &
  UserProp &
  PreviewProp &
  {
    apiDocument: ApiDocument;
    requestApiEdit: () => void;
    requestMapping: (harvest: Harvest) => void;
    onDelete: () => void;
  };

export function RepoCard(props: Props): JSX.Element {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const [infoJsx, setInfoJsx] = React.useState(null as null|JSX.Element);
  //const [catalogId, setCatalogId] = React.useState(null as string|null);
  const [lastChecked, setLastChecked] = React.useState(props.apiDocument.lastChecked);
  const [harvest, setHarvest] = React.useState(null as null | Harvest);
  const [harvestInfo, setHarvestInfo] = React.useState(noJobInfo);
  const [harvestInfoTimer, setHarvestInfoTimer] = React.useState(undefined as number|undefined);
  const [hasMapping, setHasMapping] = React.useState(undefined as boolean|undefined);
  const [conversion, setConversion] = React.useState(null as null | Conversion);
  const [conversionInfo, setConversionInfo] = React.useState(noJobInfo);
  const [conversionInfoTimer, setConversionInfoTimer] = React.useState(undefined as number|undefined);
  const [publication, setPublication] = React.useState(null as null | Publication);
  const [publicationInfo, setPublicationInfo] = React.useState(noJobInfo);
  const [publicationInfoTimer, setPublicationInfoTimer] = React.useState(undefined as number|undefined);
  const [showErrorsRequest, setShowErrorsRequest] = React.useState(false);

  //React.useEffect(() => console.log({doc: props.apiDocument.id, harvest: harvest?.id}), [props.apiDocument, harvest]);
  //React.useEffect(() => console.log({ apiDocId: props.apiDocument.id,  timerHandler}), [timerHandler]);

  function loadHarvest(): void {
    props.callAsync(
      () => harvestsApi.getHarvest(props.apiDocument.id, props.userData),
      harvestInArray => {
        //console.log({doc: props.apiDocument.id, harvests: harvestInArray});
        setHarvestInfo(info => ({ ...info, loading: false }));
        if (harvestInArray && harvestInArray.length > 0) {
          const harvest = harvestInArray[0];
          setHarvest(harvest.id ? harvest : null);
        }
      },
      { progress: false }
    );
  }

  function loadConversion(): void {
    if (harvest) {
      props.callAsync(
        () => harvestsApi.getConversion(props.apiDocument.id, harvest.id, props.userData),
        conversionInArray => {
          setConversionInfo(info => ({ ...info, loading: false }));
          if (conversionInArray && conversionInArray.length > 0) {
            const conversion = conversionInArray[0];
            setConversion(conversion.id ? conversion : null);
          }
        },
        { progress: false }
      );
    } else {
      setConversionInfo(info => ({ ...info, loading: false }));
    }
  }

  function loadPublication(): void {
    if (harvest && conversion) {
      props.callAsync(
        () => harvestsApi.getPublication(props.apiDocument.id, harvest.id, conversion.id, props.userData),
        publicationInArray => {
          setPublicationInfo(info => ({ ...info, loading: false }));
          if (publicationInArray && publicationInArray.length > 0) {
            const publication = publicationInArray[0];
            setPublication(publication.id ? publication : null);
          }
        },
        { progress: false }
      );
    } else {
      setPublicationInfo(info => ({ ...info, loading: false }));
    }
  }

  function refreshHarvestInfo(harvestId: string): void {
    //console.log({doc: props.apiDocument.id, title: props.apiDocument.doc.info.title, harvest: harvestId});
    props.callAsync(
      () => harvestsApi.getHarvestInfo(props.apiDocument.id, harvestId, props.userData),
      resp => setHarvestInfo({jobInfo: resp, loading: false}),
      { onError: console.log, progress: false }
    );
  }

  function refreshConversionInfo(harvestId: string, conversionId: string): void {
    props.callAsync(
      () => harvestsApi.getConversionInfo(props.apiDocument.id, harvestId, conversionId, props.userData),
      resp => setConversionInfo({jobInfo: resp, loading: false}),
      { onError: console.log, progress: false }
    );
  }

  function refreshPublicationInfo(harvestId: string, conversionId: string, publicationId: string): void {
    props.callAsync(
      () => harvestsApi.getPublicationInfo(props.apiDocument.id, harvestId, conversionId, publicationId, props.userData),
      resp => setPublicationInfo({jobInfo: resp, loading: false}),
      { onError: console.log, progress: false }
    );
  }

  function printConvertedMetadata(): void {
    if (harvest && conversion) {
      props.callAsync(
        () => harvestsApi.getConvertedMetadata(props.apiDocument.id, harvest.id, conversion.id, props.userData),
        console.log,
        { onError: console.log, progress: false }
      );
    }
  }

  function printPublishedMetadata(): void {
    if (harvest && conversion && publication) {
      props.callAsync(
        () => harvestsApi.getPublishedMetadata(props.apiDocument.id, harvest.id, conversion.id, publication.id, props.userData),
        console.log,
        { onError: console.log }
      );
    }
  }

  //// Hooks ////

  // Check mapping presence
  React.useEffect(
    () => {
      props.callAsync(
        () => mappingsApi.listMappings(props.userData, props.apiDocument.id),
        mappingsIds => {
          if (mappingsIds && mappingsIds.length > 0) {
            setHasMapping(true);
          } else {
            setHasMapping(false);
          }
        }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.apiDocument]
  );

  // Load harvest on start
  React.useEffect(
    loadHarvest,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Load conversion if harvest present
  React.useEffect(
    loadConversion,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [harvest]
  );

  // Load publication if conversion present
  React.useEffect(
    loadPublication,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [conversion]
  );

  // Start refreshing harvestInfo on harvest started
  React.useEffect(
    () => {
      if (harvest) {
        refreshHarvestInfo(harvest.id);
        const interval = setInterval(() => refreshHarvestInfo(harvest.id), 2000) as unknown as number;
        setHarvestInfoTimer(interval);
        return () => { clearInterval(interval); setHarvestInfoTimer(undefined); };
      } else {
        if (harvestInfoTimer) {
          clearInterval(harvestInfoTimer);
          setHarvestInfoTimer(undefined);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [harvest]
 );

  //// Start refreshing conversionInfo on conversion started
  React.useEffect(
    () => {
      if (harvest && conversion) {
        refreshConversionInfo(harvest.id, conversion.id);
        const interval = setInterval(() => refreshConversionInfo(harvest.id, conversion.id), 2000) as unknown as number;
        setConversionInfoTimer(interval);
        return () => { clearInterval(interval); setConversionInfoTimer(undefined); };
      } else {
        if (conversionInfoTimer) {
          clearInterval(conversionInfoTimer);
          setConversionInfoTimer(undefined);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [conversion]
 );

  //// Start refreshing publicationInfo on publication started
  React.useEffect(
    () => {
      if (harvest && conversion && publication) {
        refreshPublicationInfo(harvest.id, conversion.id, publication.id);
        const interval = setInterval(() => refreshPublicationInfo(harvest.id, conversion.id, publication.id), 2000) as unknown as number;
        setPublicationInfoTimer(interval);
        return () => { clearInterval(interval); setPublicationInfoTimer(undefined); };
      } else {
        if (publicationInfoTimer) {
          clearInterval(publicationInfoTimer);
          setPublicationInfoTimer(undefined);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [publication]
 );

 // Once harvest is finished/failed/paused, stop refreshing harvestInfo
 React.useEffect(
   () => {
     if ((harvestInfo.jobInfo?.status === JobStatus.FAILED ||
         harvestInfo.jobInfo?.status === JobStatus.FINISHED ||
         harvestInfo.jobInfo?.status === JobStatus.PAUSED) && harvestInfoTimer
        ) {
       clearInterval(harvestInfoTimer);
       setHarvestInfoTimer(undefined);
     }
   },
   // eslint-disable-next-line react-hooks/exhaustive-deps
   [harvestInfo]
 );

 // Once conversion is finished/failed/paused, stop refreshing conversionInfo
 React.useEffect(
   () => {
     if ((conversionInfo.jobInfo?.status === JobStatus.FAILED ||
         conversionInfo.jobInfo?.status === JobStatus.FINISHED ||
         conversionInfo.jobInfo?.status === JobStatus.PAUSED) && conversionInfoTimer
        ) {
       clearInterval(conversionInfoTimer);
       setConversionInfoTimer(undefined);
     }
   },
   // eslint-disable-next-line react-hooks/exhaustive-deps
   [conversionInfo]
 );

 // Once publication is finished/failed/paused, stop refreshing publicationInfo
 React.useEffect(
   () => {
     if ((publicationInfo.jobInfo?.status === JobStatus.FAILED ||
         publicationInfo.jobInfo?.status === JobStatus.FINISHED ||
         publicationInfo.jobInfo?.status === JobStatus.PAUSED) && publicationInfoTimer
        ) {
       clearInterval(publicationInfoTimer);
       setPublicationInfoTimer(undefined);
     }
   },
   // eslint-disable-next-line react-hooks/exhaustive-deps
   [publicationInfo]
 );

  //// Routines////

  function handleMenu(event: React.MouseEvent<HTMLButtonElement>): void {
    setAnchorEl(event.currentTarget);
  }

  function closeMenu(): void {
    setAnchorEl(null);
  }

  async function checkConnection(): Promise<void> {
    function updateChecked(ok: boolean, msg?: string|Response): void {
      const ts = new Date();
      const updatedDoc: ApiDocument = {
        ...props.apiDocument,
        lastChecked: ok ? ts : undefined,
      };
      props.callAsync(
        () => reposApi.patchAPIDocument(props.userData, updatedDoc),
        () => {
          if (msg) {
            setLastChecked(undefined);
            props.onError(msg);
          } else {
            setLastChecked(ts);
          }
          setLastChecked(ts);
        }
      );
    }

    props.onLoad(true);
    props.callAsync(
      () => reposApi.getSecCred(props.userData, props.apiDocument.id),
      secCredRes => props.callAsync(
        () => harvestsApi.getAllIds(props.apiDocument, secCredRes?.secCredId ? secCredRes?.secCredId : undefined, props.userData),
        probeResponse => {
          if (probeResponse.response.status === 200) {
            updateChecked(true);
          } else {
            updateChecked(false,`${probeResponse.response.statusText} (${probeResponse.response.status})`);
          }
        },
        { onError: err => updateChecked(false, err as Response) }
      )
    );
  }

  function startHarvest(): void {
    props.callAsync(
      () => reposApi.getSecCred(props.userData, props.apiDocument.id),
      secCred => props.callAsync(
        () => harvestsApi.startHarvest(props.apiDocument, secCred ? secCred.secCredId : undefined, props.userData),
        loadHarvest,
      )
    );
  }

  function restartHarvest(): void {
    if (harvest) {
      props.callAsync(
        () => harvestsApi.deleteHarvest(props.apiDocument.id, harvest.id, props.userData),
        startHarvest
      );
    }
  }

  async function startConversion(): Promise<void> {
    if (harvest) {
      props.callAsync(
        () => mappingsApi.listMappings(props.userData, props.apiDocument.id),
        mappingsIds => {
          if (mappingsIds.length > 0) {
            const mappingId = mappingsIds[0]; // Currently just one mapping per API
            props.callAsync(
              () => mappingsApi.getMapping(props.userData, props.apiDocument.id, mappingId),
              mapping => {
                if (mapping) {
                  props.callAsync(
                    () => harvestsApi.startConversion(props.apiDocument.id, harvest.id, mapping.id, props.userData),
                    loadHarvest,
                  );
                }
              }
            );
          }
        }
      );
    }
  }

  function restartConversion(): void {
    if (harvest && conversion) {
      props.callAsync(
        () => harvestsApi.deleteConversion(props.apiDocument.id, harvest.id, conversion.id, props.userData),
        startConversion
      );
    }
  }

  function startPublication(): void {
    if (harvest && conversion) {
      props.callAsync(
        () => reposApi.getCatalog(props.apiDocument.id, props.userData),
        catalog => {
          if (!catalog) {
            props.onError("Repository metadata not saved, yet");
          } else {
            props.callAsync(
              () => harvestsApi.startPublication(props.apiDocument.id, catalog.id, harvest.id, conversion.id, props.userData),
              loadPublication,
            );
          }
        }
      );
    }
  }

  function restartPublication(): void {
    startPublication();
  }

  /////// Rendering /////////

  function renderMenu(): JSX.Element {
    return (
      <>
        <IconButton onClick={handleMenu}>
          <MoreVertIcon />
        </IconButton>
        <Menu id="basic-menu" anchorEl={anchorEl} open={menuOpen} onClose={closeMenu}>
          <MenuItem onClick={closeMenu}>
            <ListItemIcon>
              <ShareIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Sharing</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              closeMenu();
              props.onDelete();
            }}
          >
            <ListItemIcon sx={{ color: "warning.main" }}>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText sx={{ color: "warning.main" }}>Delete</ListItemText>
          </MenuItem>
        </Menu>
      </>
    );
  }

  function renderInfoPane(): JSX.Element {
    return (
      <Box sx={modalViewCss}>
        {infoJsx || <></>}
      </Box>
    );
  }

  function renderErrorsView(): JSX.Element {
    //console.log(props.apiDocument.errors);
    return (
      <Stack direction="column" spacing={2} sx={modalViewCss}>
        {props.apiDocument.errors.schemaErrors
          .map((err, i) => (
            <Paper key={i} elevation={5} sx={{ m: 1, p: 1, color: "error.dark" }}>
              {err.error}
            </Paper>
          ))}
        {props.apiDocument.errors.logicErrors
          .map((err, i) => (
            <Paper key={i} elevation={5} sx={{ m: 1, p: 1, color: "error.dark" }}>
              {JSON.stringify(err)}
            </Paper>
          ))}
      </Stack>
    );
  }

  function renderApiValidity(): JSX.Element {
    return (
      <>
        <Grid item xs={6}>
          Description
        </Grid>
        <Grid item xs={3}>
          <Stack direction="row" justifyContent="center">
            {props.apiDocument.errors.schemaErrors.length > 0 || props.apiDocument.errors.logicErrors.length ? (
              <Tooltip title="SmartAPI errors">
                <IconButton onClick={() => setShowErrorsRequest(true)}>
                  <img src={smartApiLogoErrors} width={iconSize} />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="SmartAPI valid">
                <img src={smartApiLogoOK} width={iconSize} />
              </Tooltip>
            )}
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <Stack direction="row" justifyContent="flex-end">
            <Tooltip title="Edit Repository Description">
              <div>
                <Button color="primary" onClick={props.requestApiEdit}>
                  Edit
                </Button>
              </div>
            </Tooltip>
          </Stack>
        </Grid>
      </>
    );
  }

  function renderApiChecked(): JSX.Element {
    function showCheckedInfo(): void {
      if (lastChecked) {
        setInfoJsx(
          <Paper elevation={5} sx={{ m: 1, p: 1 }}>
            <Stack direction="column" alignItems="center" spacing={2}>
              <div>Connection working, last checked on {new Date(lastChecked).toLocaleString()}</div>
              <Button variant="contained" onClick={checkConnection}>
                Check now
              </Button>
            </Stack>
          </Paper>
        );
      }
    }

    return (
      <>
        <Grid item xs={6}>
          Connection
        </Grid>
        <Grid item xs={3}>
          <Stack direction="row" justifyContent="center">
            {lastChecked ?
              <Tooltip title={`Connection working, last checked on ${new Date(lastChecked).toLocaleString()}`}>
                <IconButton onClick={showCheckedInfo}>
                  <CheckedIcon color="success" sx={{ fontSize: iconSize }} />
                </IconButton>
              </Tooltip>
            :
              <Tooltip title="Connection not checked / not working">
                <NotCheckedIcon color="error" sx={{ fontSize: iconSize }} />
              </Tooltip>
            }
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <Stack direction="row" justifyContent="flex-end">
            <Tooltip title="Check connection">
              <div>
                <Button onClick={checkConnection}>Check</Button>
              </div>
            </Tooltip>
          </Stack>
        </Grid>
      </>
    );
  }

  function renderMapping(): JSX.Element {
    return (
      <>
        <Grid item xs={6}>
          Mapping
        </Grid>
        <Grid item xs={3}>
          <Stack direction="row" justifyContent="center">
            {hasMapping === true ?
              <Chip
                color="success"
                variant="filled"
                label="Saved"
              />
            : <Chip
                color="default"
                variant="filled"
                disabled={true}
                label="None"
              />
            }
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <Stack direction="row" justifyContent="flex-end">
            <Tooltip title={!harvest ? "Metadata harvesting must be finished first" : ""}>
              <div>
                <Button
                  color="primary"
                  disabled={!harvest}
                  onClick={() => { if (harvest) { props.requestMapping(harvest); }}}>
                  Edit
                </Button>
              </div>
            </Tooltip>
          </Stack>
        </Grid>
      </>
    );
  }

  function renderOwnRepo(): JSX.Element {
    return (
      <>
        <Card sx={{ width: cardWidth, minHeight: cardHeight }} elevation={5}>
          <CardContent>
            <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
              <Typography variant="h5" sx={{color: GREY[800]}} gutterBottom>
                {props.apiDocument.doc.info.title}
              </Typography>
              {renderMenu()}
            </Stack>
            <Grid
              container
              direction="row"
              columnSpacing={2}
              justifyContent="space-between"
              alignItems="center"
            >
              {renderApiValidity()}
              {renderApiChecked()}
              <JobControlLine
                title="Metadata harvesting"
                jobInfoRecord={harvestInfo}
                disableAction={!lastChecked}
                disableMessage="Connection must be fully working first"
                startAction={startHarvest}
                restartAction={restartHarvest}
              />
              {renderMapping()}
              <JobControlLine
                title="Conversion"
                jobInfoRecord={conversionInfo}
                disableAction={!harvest || !hasMapping}
                disableMessage="Metadata harvesting & Mapping must be finished first"
                startAction={startConversion}
                restartAction={restartConversion}
                extraButtons={
                  <Button
                    variant="outlined"
                    startIcon={<ViewIcon />}
                    onClick={printConvertedMetadata}
                  >
                    Show in the console
                  </Button>
                }
              />
              <JobControlLine
                title="FDP publication"
                jobInfoRecord={publicationInfo}
                disableAction={!harvest || !conversion}
                disableMessage="Conversion must be finished first"
                startAction={startPublication}
                restartAction={restartPublication}
                extraButtons={
                  <>
                    <Button
                      variant="outlined"
                      startIcon={<ViewIcon />}
                      onClick={printPublishedMetadata}
                    >
                      Show in the console
                    </Button>
                    <Button
                      variant="outlined"
                      startIcon={<ViewIcon />}
                      onClick={() => window.open(config.FDP_URL)}
                    >
                      Open FDP
                    </Button>
                  </>
                }
              />
            </Grid>
          </CardContent>
        </Card>
        <Modal open={infoJsx !== null} onClose={() => setInfoJsx(null)}>
          {renderInfoPane()}
        </Modal>
        <Modal open={showErrorsRequest} onClose={() => setShowErrorsRequest(false)}>
          {renderErrorsView()}
        </Modal>
      </>
    );
  }

  function renderOthersRepo(): JSX.Element {
    return (
      <Card sx={{
        width: cardWidth,
        minHeight: cardHeight,
        color: GREY[600],
        backgroundColor: GREY[200],
        }} elevation={5}
      >
        <CardContent>
          <Stack direction="column" justifyContent="center" alignItems="stretch">
            <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
              <Typography variant="h5" gutterBottom>
                {props.apiDocument.doc.info.title}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography
                variant="h6"
                sx={{fontStyle: "italic"}}
              >
                Other user&apos;s repository
              </Typography>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    );
  }

  return (
    props.apiDocument.userId === props.userData.user.id ?
      renderOwnRepo()
    : renderOthersRepo()
  );
}
