import _ from "lodash";
import React from "react";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import type { TryCancelProp, IndicationProps, PreviewProp, UserProp, CallAsyncProp } from "@app/Components/definitions";
import { RepoDescription } from "./RepoDescription";
import type { ApiDocument } from "@app/Api/repos";
import * as reposApi from "@app/Api/repos";
import { FSContainer } from "@app/Components/UIComponents";
import { RepoCard } from "./RepoCard";
import { Mapper } from "@app/Components/Mapper";
import type { Harvest } from "@app/Api/harvests";

type ApiPageRequest = {
  apiDoc: ApiDocument|null|undefined;
};

type MappingPageRequest = {
  apiDoc: ApiDocument;
  harvest: Harvest;
};

type Props = IndicationProps & CallAsyncProp &  UserProp & PreviewProp & TryCancelProp;

export function Repositories(props: Props): JSX.Element {
  const [apis, setApis] = React.useState(null as null | ApiDocument[]);
  const [apiFormRequest, setApiFormRequest] = React.useState(null as null|ApiPageRequest);
  const [mapperRequest, setMapperRequest] = React.useState(null as null|MappingPageRequest);
  // Using the function in a state needs to be wrapped: https://medium.com/swlh/how-to-store-a-function-with-the-usestate-hook-in-react-8a88dd4eede1
  const [repoDescriptionCanCancelFn, setRepoDescriptionCanCancelFn] = React.useState(null as null | (() => () => boolean));
  const [mapperCanCancelFn, setMapperCanCancelFn] = React.useState(null as null | (() => () => boolean));

  //React.useEffect(() => console.log(apis?.map(a => a.id)), [apis]);

  async function loadApis(): Promise<void> {
    props.callAsync(
      () => reposApi.getAPIDocuments(),
      resp => {
        const myApis = (resp || []).filter(apiDoc => apiDoc.userId === props.userData.user.id);
        const apisSorted = _.sortBy(myApis, (apiDoc: ApiDocument) => apiDoc.doc.info.title);
        setApis(apisSorted);
      }
    );
  }

  function loadApiDocument(apiId: string): void {
    props.callAsync(
      () => reposApi.getAPIDocument(apiId, props.userData),
      apiDoc => setApiFormRequest({ apiDoc })
    );
  }

  function deleteApi(id: string): void {
    props.callAsync(
      () => reposApi.deleteAPIDocument(id, props.userData),
      loadApis
    );
  }

  function cancelRepoDescription(): void {
    if (repoDescriptionCanCancelFn !== null) {
      props.tryCancel(
        repoDescriptionCanCancelFn(),
        () => {
          setApiFormRequest(null);
          loadApis();
        }
      );
    } else {
      props.onError("Internal error: canCancelFunction not set by RepoDescription");
    }
  }

  function cancelMapper(): void {
    if (mapperCanCancelFn !== null) {
      props.tryCancel(
        mapperCanCancelFn(),
        () => {
          setMapperRequest(null);
          loadApis();
        }
      );
    } else {
      props.onError("Internal error: canCancelFunction not set by Mapper");
    }
  }

  //// Hooks ////

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => { loadApis(); }, []);

  //// Rendering ////

  function renderApiList(): JSX.Element {
    return apis === null ? (
      <></>
    ) : (
      <Grid container direction="row" spacing={2}>
        {apis.map((doc, i) =>
          <Grid item key={i}>
            <RepoCard
              {...props}
              apiDocument={doc}
              requestApiEdit={() => setApiFormRequest({ apiDoc: doc })}
              requestMapping={harvest => setMapperRequest({ apiDoc: doc, harvest })}
              onPreview={props.onPreview}
              onDelete={() => deleteApi(doc.id)}
            />
          </Grid>
        )}
      </Grid>
    );
  }

  function renderApisHeader(): JSX.Element {
    const noOfApis = apis?.length || 0;
    return (
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{mb: 2}}>
        <Typography variant="h4">
          {noOfApis > 0 ?
            `${noOfApis} repositories`
          : "No repositories"
          }
        </Typography>
      </Stack>
    );
  }

  function renderApis(): JSX.Element {
    return (
      <Stack direction="column" spacing={1}>
        {renderApisHeader()}
        <Stack direction="column" alignItems="flex-start" spacing={4}>
          {renderApiList()}
          <Button
            variant="contained"
            size="large"
            startIcon={<AddIcon />}
            onClick={() => setApiFormRequest({ apiDoc: undefined })}
          >
            New Repository
          </Button>
        </Stack>
      </Stack>
    );
  }

  return (
    <FSContainer fullWidth>
      {apiFormRequest ? (
        <RepoDescription
          {...props}
          apiDocument={apiFormRequest.apiDoc || undefined}
          onApiDocumentSaved={loadApiDocument}
          setCanCancelFn={setRepoDescriptionCanCancelFn}
          requestCancel={cancelRepoDescription}
        />
      ) : mapperRequest ? (
        <Mapper
          {...props}
          apiDocument={mapperRequest.apiDoc}
          harvest={mapperRequest.harvest}
          setCanCancelFn={setMapperCanCancelFn}
          requestCancel={cancelMapper}
        />
      ) : (
        renderApis()
      )}
    </FSContainer>
  );
}
//
