import _ from "lodash";
import Im from "immutable";
import * as R from "result-async";
import * as P from "pipeout";
import { type HttpError, get } from "@app/Api/http";
import type { SemanticPropertyInfo } from "@fair-space/core/types/mapping";
import { Endorsement, Domain, DomainProperty } from "@fair-space/core/types/mapping";
import { getConfig } from "@app/config";
import { UserData } from "@app/Models/user";

const config = getConfig();

// Reexports {{{1

export type { SemanticPropertyInfo };
export { Endorsement, Domain };

// URLs {{{1

const saPropertiesUrl = `${config.SERVER_URL}/rdf/sa-props`;
const sadPropertiesUrl = `${config.SERVER_URL}/rdf/sa-distribution-props`;
const getTermInfoUrl = `${config.SERVER_URL}/rdf/termInfo`;
const getSubpropertiesUrl = `${config.SERVER_URL}/rdf/subProperties`;
const getCatalogPropsUrl = `${config.SERVER_URL}/rdf/catalog-props`;

// Aux {{{1

export interface SpqlResult {
  // SPARQL result format
  names: string[];
  values: string[][];
}

export function mergeSqplResults(spqlRes1: SpqlResult, spqlRes2: SpqlResult): SpqlResult {
  if (!_.isEqual(spqlRes1.names, spqlRes2.names)) {
    throw new Error("names do not match");
  } else {
    return {
      names: spqlRes1.names,
      values: [...spqlRes1.values, ...spqlRes2.values],
    };
  }
}

export function sanitizeUrl(shs: string): string {
  return (
    shs ?
      _.first(shs) === "<" && _.last(shs) === ">" ?
        shs.substring(1, shs.length - 1)
      : shs
    : ""
  );
}

function sanitizeSpqlResult(result: SpqlResult): SpqlResult {
  function sanitizeShString(shs: string): string {
    return (
      shs ?
        shs.replace(/"(.+)"(@..)?/, "$1")
      : ""
    );
  }

  return {
    names: result.names.map(sanitizeShString),
    values: result.values.map(row => row.map(sanitizeShString)),
  };
}

// The function hot-fixes the issue with multiple definition triples resulting
// in duplication of result lines. It does so by taking just the first line.
// The equality of lines is based on the value under fieldIndex.
function squashSpqlResult(result: SpqlResult, fieldIndex: number): SpqlResult {
  type Accumulator = {
    lines: string[][];
    urisSet: Im.Set<string>;
  };
  const newValues = result.values.reduce(
    (res: Accumulator, line: string[]) => {
      const value = line[fieldIndex];
      return (
        res.urisSet.has(value) ?
          res
        : { lines: [...res.lines, line], urisSet: res.urisSet.add(value) }
      );
    },
    { lines: [], urisSet: Im.Set<string>() }
  );

  return {
    names: result.names,
    values: newValues.lines,
  };
}

async function doQuery(url: string, userData: UserData): R.ResultP<SpqlResult, HttpError> {
  const respR = await get<SpqlResult>(url, userData);
  return P.pipe
    (respR)
    .thru(R.okThen<SpqlResult, SpqlResult>(sanitizeSpqlResult))
    .value();
}

function decodeEndorsementUri(uri: string): Endorsement | undefined {
  return uri.includes("Mandatory")
    ? Endorsement.MANDATORY
    : uri.includes("Recommended")
    ? Endorsement.RECOMMENDED
    : uri.includes("Optional")
    ? Endorsement.OPTIONAL
    : undefined;
}

type SpqlStructure = {
  propertyField: string;
  parentField: string|undefined;
  endorsementField: string|undefined;
  rangeField: string;
  nameField: string;
  definitionField: string|undefined;
}

function spqlIndexOf(spql: SpqlResult, field: string) {
  return spql.names.findIndex(n => n === field);
}

// Properties {{{1

function getSubproperties(userData: UserData, propertyUri: DomainProperty, domain: Domain): R.ResultP<SpqlResult, HttpError> {
  return doQuery(`${getSubpropertiesUrl}/${encodeURIComponent(propertyUri)}/${encodeURIComponent(domain)}`, userData);
}

function mkSemanticPropertyInfos(spqlRes: SpqlResult, spqlStruc: SpqlStructure, domain: Domain): R.Result<SemanticPropertyInfo[], string> {
  const propertyIndex = spqlIndexOf(spqlRes, spqlStruc.propertyField);
  const parentIndex = spqlStruc.parentField ? spqlIndexOf(spqlRes, spqlStruc.parentField) : undefined;
  const endorsementIndex = spqlStruc.endorsementField ? spqlIndexOf(spqlRes, spqlStruc.endorsementField) : undefined;
  const rangeIndex = spqlIndexOf(spqlRes, spqlStruc.rangeField);
  const nameIndex = spqlIndexOf(spqlRes, spqlStruc.nameField);
  const definitionIndex = spqlStruc.definitionField ? spqlIndexOf(spqlRes, spqlStruc.definitionField) : undefined;
  if (propertyIndex < 0) {
    console.log(spqlRes);
    return R.error(`propertyField "${spqlStruc.propertyField}" is missing`);
  } else if (parentIndex && parentIndex < 0) {
    console.log(spqlRes);
    return R.error(`parentField "${spqlStruc.parentField}" is missing`);
  } else if (rangeIndex < 0) {
    console.log(spqlRes);
    return R.error(`rangeField "${spqlStruc.rangeField}" is missing`);
  } else if (nameIndex < 0) {
    console.log(spqlRes);
    return R.error(`nameField "${spqlStruc.nameField}" is missing`);
  } else if (definitionIndex && definitionIndex < 0) {
    console.log(spqlRes);
    return R.error(`definitionField "${spqlStruc.nameField}" is missing`);
  } else {
    const squashed = squashSpqlResult(spqlRes, propertyIndex);
    const props: Array<SemanticPropertyInfo | undefined> = squashed.values.map(row => {
      const uri = row[propertyIndex];
      const parentProperty = parentIndex !== undefined ? row[parentIndex] : undefined;
      const name = row[nameIndex];
      const endorsement = endorsementIndex && endorsementIndex > 0 ? decodeEndorsementUri(row[endorsementIndex]) : undefined;
      const range = row[rangeIndex];
      const definition = definitionIndex !== undefined ? row[definitionIndex] : "";
      return { uri, name, endorsement, domain, range, parentProperty, definition };
    });
    return R.ok(props as SemanticPropertyInfo[]);
  }
}

async function getProperties(getFn: (userData: UserData) => R.ResultP<SpqlResult, HttpError>, property: DomainProperty, domain: Domain, userData: UserData): R.ResultP<SemanticPropertyInfo[], HttpError> {
  const propsSpqlStruc: SpqlStructure = {
    propertyField: "prop",
    parentField: undefined,
    endorsementField: "endorsement",
    rangeField: "range",
    nameField: "name",
    definitionField: "definition",
  };
  const subPropsSpqlStruc: SpqlStructure = {
    propertyField: "subproperty",
    parentField: "property",
    endorsementField: "endorsement",
    rangeField: "range",
    nameField: "name",
    definitionField: "definition",
  };

  function combine([propsRes, subPropsRes]: SpqlResult[]): R.Result<SemanticPropertyInfo[], string> {
    const props = mkSemanticPropertyInfos(propsRes, propsSpqlStruc, domain);
    const subProps = mkSemanticPropertyInfos(subPropsRes, subPropsSpqlStruc, domain);
    return P.pipe
      (R.allOk<SemanticPropertyInfo[], string>([props, subProps]))
      .thru(R.okThen<SemanticPropertyInfo[][], SemanticPropertyInfo[]>(([props, subProps]) => [...props, ...subProps]))
      .value();
  }

  return P.pipeA
    (await R.allOkAsync<SpqlResult, HttpError>([getFn(userData), getSubproperties(userData, property, domain)]))
    .thru(R.okChain<SpqlResult[], SemanticPropertyInfo[], string>(combine))
    .value();

  //const propsSubProps: R.Result<SpqlResult[], HttpError> = await R.allOkAsync<SpqlResult, HttpError>([getFn(userData), getSubproperties(userData, property, domain)]);
  //const res = R.okChain<SpqlResult[], SemanticPropertyInfo[], string>(combine)(propsSubProps);
  //return res;

  //return new Promise((resolve, reject) =>
    //Promise.all([getFn(userData), getSubproperties(userData, property, domain)]).then(
      //([propsRes, subPropsRes]) => {
        ////console.log({propsRes, subPropsRes});
        //const props = propsRes ? mkSemanticPropertyInfos(propsRes, propsSpqlStruc, domain) : [];
        //const subProps = subPropsRes ? mkSemanticPropertyInfos(subPropsRes, subPropsSpqlStruc, domain) : [];
        //resolve([...props, ...subProps]);
      //},
      //err => reject(err)
    //)
  //);
}

export function getSemanticArtefactProperties(userData: UserData): R.ResultP<SemanticPropertyInfo[], HttpError> {
  return getProperties(
    userData => doQuery(saPropertiesUrl, userData),
    DomainProperty.SEMANTIC_ARTEFACT,
    Domain.SEMANTIC_ARTEFACT,
    userData
  );
}

export function getSemanticArtefactDistributionProperties(userData: UserData): R.ResultP<SemanticPropertyInfo[], Response|string> {
  return getProperties(
    userData => doQuery(sadPropertiesUrl, userData),
    DomainProperty.SEMANTIC_ARTEFACT_DISTRIBUTION,
    Domain.SEMANTIC_ARTEFACT_DISTRIBUTION,
    userData
  );
}

// Term Info {{{1

export function getTermInfo(uri: string, userData: UserData): R.ResultP<SpqlResult, Response|string> {
  return doQuery(`${getTermInfoUrl}/${encodeURIComponent(uri)}`, userData);
}

// DCAT {{{1

export async function getDcatCatalogProperties(userData: UserData): R.ResultP<SemanticPropertyInfo[], Response|string> {
  return getProperties(
    userData => doQuery(getCatalogPropsUrl, userData),
    DomainProperty.DCAT_CATALOG,
    Domain.DCAT_CATALOG,
    userData
  );
}

