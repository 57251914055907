import React from "react";
import type { SxProps } from "@mui/system";
import {
  Modal,
  Paper
} from "@mui/material";

const viewCss: SxProps = {
  position: "absolute",
  top: 50,
  height: "calc(100vh - 100px)",
  left: "calc(100vw / 8)",
  width: "calc(100vw / 8 * 6)",
  overflow: "auto",
  boxShadow: 5,
  p: 2,
};

interface Props {
  contents: JSX.Element|null;
  onClosed: () => void;
}

export function ModalView(props: Props): JSX.Element {

  function renderView(): JSX.Element {
    return (
      <Paper sx={viewCss}>
        {props.contents}
      </Paper>
    );
  }

  return (
    <Modal
      open={props.contents !== null}
      onClose={props.onClosed}
    >
      {renderView()}
    </Modal>
  );
}

